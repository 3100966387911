import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeatureToggleService, LocalStorageService } from '../../store/services';
import { FormBuilder } from '@angular/forms';
import { IFeatureToggleItem } from '../../store/interfaces';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-feature-flags-dialog',
    templateUrl: './feature-flags-dialog.component.html',
    styleUrls: ['./feature-flags-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureFlagsDialogComponent implements OnInit {
    featuresForm = this.formBuilder.group({});
    featuresData = [];
    selectedWhiteLabel = skin.whiteLabel;
    constructor(
        public dialogRef: MatDialogRef<FeatureFlagsDialogComponent>,
        private featuresToggleService: FeatureToggleService,
        private formBuilder: FormBuilder,
        private localStorageService: LocalStorageService,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {}

    ngOnInit() {
        this.featuresData = this.featuresToggleService.getFeaturesData();
        if (this.featuresData.length) {
            this.prepareFormToDisplay();
        }
    }

    prepareFormToDisplay() {
        return this.featuresData.map((i: IFeatureToggleItem) => {
            this.featuresForm.addControl(i.featureName, this.formBuilder.control(i.featureValue));
        });
    }

    close(): void {
        this.dialogRef.close();
    }

    save(data): void {
        let dataToUpdate = [];
        Object.entries(data).map(([k, v]) => {
            dataToUpdate.push({ featureName: k, featureValue: v });
        });
        this.featuresToggleService.updateFeaturesData(dataToUpdate);
        this.localStorageService.setItem('whitelabel', this.selectedWhiteLabel);
        this.dialogRef.close();
    }
}

import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PhoneInputComponent } from 'src/app/shared/phone-input/phone-input.component';
import { AuthActions } from 'src/app/store/actions/auth.actions';
import {
    BASE_REDIRECT_URL,
    CANADA_STATES,
    COUNTRIES_NAMES,
    TNT_COUNTRIES_LIST,
    USA_STATES,
} from 'src/app/store/constants';
import { ISocialUserInfo, IStatusLabel } from 'src/app/store/interfaces';
import { selectIsStatusLabel } from 'src/app/store/selectors/status.selectors';
import { AuthService } from 'src/app/store/services/auth/auth.service';
import { IAppState } from 'src/app/store/state/app.state';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-create-accout-from-link',
    templateUrl: './create-accout-from-link.component.html',
    styleUrls: ['./create-accout-from-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAccoutFromLinkComponent implements OnInit {
    @ViewChild(PhoneInputComponent) phoneInputComponent: PhoneInputComponent;
    loginError$: Observable<IStatusLabel>;
    form: FormGroup = this.formBuilder.group({
        addressCountry: ['', [Validators.required]],
        state: [''],
        terms: [false, [Validators.requiredTrue]],
        privacy: [false, [Validators.requiredTrue]],
    });
    isPrivacyChecked: boolean;
    isTermsChecked: boolean;
    socialUserInfo: ISocialUserInfo;
    readonly countries = TNT_COUNTRIES_LIST;
    readonly allCountries = COUNTRIES_NAMES;
    readonly canadaStates = CANADA_STATES;
    readonly usaStates = USA_STATES;
    skin = skin;

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private store: Store<IAppState>,
    ) {}

    ngOnInit(): void {
        if (this.authService.lastLoginUserInfo) {
            this.socialUserInfo = this.authService.lastLoginUserInfo;
            this.loginError$ = this.store.select(selectIsStatusLabel);
        } else {
            this.router.navigate(['/']);
        }
    }

    onSubmit(isValid: boolean) {
        this.phoneInputComponent.validatePhoneForm();
        this.isPrivacyChecked = this.form.controls['privacy'].value;
        this.isTermsChecked = this.form.controls['terms'].value;

        const phoneCountryData = this.phoneInputComponent.countryCtrl.value;
        const phoneNumber = this.phoneInputComponent.phoneNumb.value;

        if (
            isValid &&
            this.phoneInputComponent.countryCtrl.valid &&
            this.phoneInputComponent.phoneNumb.valid
        ) {
            const userData = {
                accessToken: this.socialUserInfo.token,
                whitelabel: skin.whiteLabel,
                client_id: skin.clientId,
                account_icon_url: this.socialUserInfo.photoUrl,
                redirect_uri: `${BASE_REDIRECT_URL}/api/internal/v1/oauth_redirect`,
                firstName: this.socialUserInfo.firstName,
                lastName: this.socialUserInfo.lastName,
                phone: `+${phoneCountryData[2]}${phoneNumber}`,
                phoneCountryCode: phoneCountryData[1],
                country: this.form.value.addressCountry,
                state:
                    this.form.value.addressCountry == 'CA' || this.form.value.addressCountry == 'US'
                        ? this.form.value.state
                        : '',
                sourceAppleLogin: 'web',
            };
            console.log(userData);

            this.store.dispatch(
                AuthActions.loginWithSocial({
                    userData,
                    provider: this.socialUserInfo.provider.toLocaleLowerCase(),
                }),
            );
        }
    }
}

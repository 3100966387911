import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { skin } from 'src/white-labels';
import { ApiService, LocalStorageService, WindowService } from '..';
import { DevicesActions } from '../../actions/devices.actions';
import { EventsActions } from '../../actions/events.actions';
import { MapActions } from '../../actions/map.actions';
import { GOOGLE_MAP_API_KEY, NOTIFICATION_TYPES } from '../../constants';
import { ZoneType } from '../../constants/common.constants';
import { IDeviceHistory, ILatLng, ILocation, IZone, IZoneSize } from '../../interfaces';
import { IDeviceByToken, IDeviceFull, IDeviceShort } from '../../interfaces/devices.interface';
import { IAppState } from '../../state/app.state';
import { DeviceMarker } from './device-marker.enum';
import createHTMLMapMarker from './html-map-marker.class';
import { SnackbarService } from '../snackbar.service';
declare const markerClusterer: any;

@Injectable({ providedIn: 'root' })
export class GoogleMapService {
    private googleMap: GoogleMap;
    private markerClusterer: any;
    private selecteedDeviceCircle: google.maps.Circle;
    private markers: any = []; //google.maps.OverlayView[]
    private userPin: google.maps.OverlayView;
    private rectangles: google.maps.Rectangle[] = [];
    private infoWindows: google.maps.InfoWindow[] = [];
    private polyline: google.maps.Polyline;
    private directionsRenderer: google.maps.DirectionsRenderer[] = [];

    private PRIMARY_COLOR = skin.primaryColor;
    private SOS_MARKER_COLOR = '#e53935';
    private INACTIVE_MARKER_COLOR = '#999999';
    private NO_MOVEMENT_MARKER_COLOR = '#F2C111';
    private SLEEP_MODE_COLOR = '#161390';
    private ONLINE_MODE_COLOR = '#009C56';

    private MAP_MAX_ZOOM = 21 as const;

    private zoneSize = new Subject<IZoneSize>();

    isShowDeviceName = true;

    public zoneSizeChange = this.zoneSize.asObservable();
    public panorama: google.maps.StreetViewPanorama;

    initRouteCalled: boolean;

    constructor(
        private zone: NgZone,
        private router: Router,
        private httpClient: HttpClient,
        private store: Store<IAppState>,
        private window: WindowService,
        private apiService: ApiService,
        public localStorage: LocalStorageService,
        private snackBar: SnackbarService,
    ) {}

    mapApiLoaded = (): Observable<boolean> => {
        const language = this.localStorage.getItem('language');
        return this.httpClient
            .jsonp(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&language=${language}&libraries=geometry,places`,
                'callback',
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    };

    set map(map: GoogleMap) {
        this.googleMap = map;
        this.googleMap.googleMap?.setOptions({
            minZoom: 3,
            maxZoom: this.MAP_MAX_ZOOM,
            restriction: {
                latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
            },
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            mapTypeControl: false,
        });
    }

    get map() {
        return this.googleMap;
    }

    initStreetView(inPano: HTMLElement) {
        this.panorama = new google.maps.StreetViewPanorama(inPano, {
            pov: {
                heading: 300,
                pitch: 0,
            },
            zoom: 0,
        });
    }

    setStreetViewPanorama(lat: number, lng: number) {
        this.panorama.setPosition({ lat, lng });
        this.googleMap.googleMap?.setStreetView(this.panorama);
    }

    zoomIn(): void {
        var currentZoomLevel = this.googleMap.getZoom();
        if (currentZoomLevel != this.MAP_MAX_ZOOM) {
            this.googleMap.googleMap.setZoom(currentZoomLevel + 1);
        }
    }

    zoomOut(): void {
        var currentZoomLevel = this.googleMap.getZoom();
        if (currentZoomLevel != 0) {
            this.googleMap.googleMap.setZoom(currentZoomLevel - 1);
        }
    }

    changeMapType(mapType: string): void {
        this.googleMap.googleMap.setMapTypeId(google.maps.MapTypeId[mapType]);
    }

    getMapType(): string {
        return this.googleMap.googleMap.getMapTypeId();
    }

    getUserLocation(): void {
        const navigator = this.window.getWindow.navigator;

        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position: GeolocationPosition) => {
                    if (this.userPin) {
                        this.removeUserMarker();
                    }

                    const location: ILatLng = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    const bounds = new google.maps.LatLngBounds();
                    bounds.extend(new google.maps.LatLng(location));

                    this.userPin = createHTMLMapMarker({
                        latlng: new google.maps.LatLng(location.lat, location.lng),
                        map: this.googleMap.googleMap,
                        html: this.getUserPin(),
                        isSetToMap: true,
                    });

                    this.googleMap.fitBounds(bounds);
                    this.googleMap.googleMap?.setCenter(bounds.getCenter());
                },
                (error) => console.error(error.message),
            );
        }
    }

    fitBounds(devices: IDeviceShort[], zones: IZone[], device?: IDeviceFull, locations?: ILocation[]): void {
        const bounds = new google.maps.LatLngBounds();

        if (devices.length) {
            devices
                .filter((device) => device.lat && device.lng)
                .forEach((device: IDeviceShort) => {
                    let lat = device.lat;
                    let lng = device.lng;
                    if (device.location_ping) {
                        lat = device.location_ping.lat;
                        lng = device.location_ping.lng;
                    }
                    const location: ILatLng = { lat, lng };
                    bounds.extend(new google.maps.LatLng(location));
                });
        }

        if (zones.length) {
            zones.forEach((zone: IZone) => {
                if (zone.type === ZoneType.POLYGON) {
                    zone.preferences.vertices.forEach((e) => bounds.extend(e));
                } else if (zone.type === ZoneType.RECTANGLE || zone.type === ZoneType.DEVICE_GEOZONE) {
                    const northeast = zone.preferences.vertices.northeast;
                    bounds.extend(
                        new google.maps.LatLng({
                            lat: northeast.lat,
                            lng: northeast.lng,
                        }),
                    );
                    const southwest = zone.preferences.vertices.southwest;
                    bounds.extend(
                        new google.maps.LatLng({
                            lat: southwest.lat,
                            lng: southwest.lng,
                        }),
                    );
                } else if (zone.type === ZoneType.POLYLINE) {
                    zone.preferences.vertices.forEach((e) => bounds.extend(e));
                } else if (zone.type === ZoneType.WIFI) {
                    const zoneCenter = zone.preferences.center;
                    bounds.extend(
                        new google.maps.LatLng({
                            lat: zoneCenter.lat,
                            lng: zoneCenter.lng,
                        }),
                    );
                }
            });
        }

        if (device) {
            bounds.extend(
                new google.maps.LatLng({ lat: device.location_ping.lat, lng: device.location_ping.lng }),
            );
        }

        if (locations) {
            locations.forEach((inLlocation: ILocation) => {
                let lat = inLlocation.deviceLastLocation.latitude;
                let lng = inLlocation.deviceLastLocation.longitude;
                const location: ILatLng = { lat, lng };
                bounds.extend(new google.maps.LatLng(location));
            });
        }

        this.googleMap.fitBounds(bounds);
        this.googleMap.googleMap?.setCenter(bounds.getCenter());
    }

    addMarkerCluster(devices: IDeviceShort[]): void {
        this.markers = devices
            .filter((device) => device.lat && device.lng)
            .map((device: IDeviceShort, i) => {
                let lat = device.lat;
                let lng = device.lng;
                if (device.location_ping) {
                    lat = device.location_ping.lat;
                    lng = device.location_ping.lng;
                }

                const marker = createHTMLMapMarker({
                    latlng: new google.maps.LatLng(lat, lng),
                    map: this.googleMap.googleMap,
                    html: this.getMarkerTemplate(device, device.age),
                    id: device.device_id,
                    isSetToMap: false,
                });

                const circle = this.getMarkerRadius(device, { lat, lng });
                circle.bindTo('map', marker, 'map');

                marker.addListener('click', () => {
                    if (device.allSOSEvents?.length) {
                        this.store.dispatch(
                            DevicesActions.updateDeviceEvent({ events: device.allSOSEvents }),
                        );
                    }
                    this.zone.run(() => this.router.navigate(['/map/devices/', device.device_id]));
                });

                return marker;
            });

        this.setMarkerCluster();
    }

    removeMarkerById(device: IDeviceShort, isVisible: boolean) {
        this.markers.forEach((marker) => {
            if (device.device_id === marker['id']) {
                if (isVisible) {
                    this.markerClusterer.removeMarker(marker);
                } else {
                    this.markerClusterer.addMarker(marker);
                }
            }
        });
    }

    removeUserMarker() {
        this.userPin['html'] = this.getUserPin(false);
        this.userPin.setMap(this.googleMap.googleMap);
    }

    removeMarkersByIds(devices: IDeviceShort[], isVisibleMarkers: boolean) {
        devices.forEach((device) => {
            this.markers.forEach((marker) => {
                if (device.device_id === marker['id']) {
                    if (!isVisibleMarkers) {
                        this.markerClusterer.removeMarker(marker);
                    } else {
                        this.markerClusterer.addMarker(marker);
                    }
                }
            });
        });
    }

    addZones(zones: IZone[]): void {
        this.rectangles = zones.map((zone: IZone) => {
            let zoneRect;
            if (zone.type === ZoneType.POLYGON) {
                zoneRect = new google.maps.Polygon({
                    strokeColor: '#FFBD13',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: '#FFBD13',
                    fillOpacity: 0.3,
                    map: this.googleMap.googleMap,
                    paths: zone.preferences.vertices,
                });
            } else if (zone.type === ZoneType.RECTANGLE || zone.type === ZoneType.DEVICE_GEOZONE) {
                zoneRect = new google.maps.Rectangle({
                    strokeColor: this.PRIMARY_COLOR,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: this.PRIMARY_COLOR,
                    fillOpacity: 0.3,
                    map: this.googleMap.googleMap,
                    bounds: {
                        north: zone.preferences.vertices.northeast.lat,
                        east: zone.preferences.vertices.northeast.lng,
                        south: zone.preferences.vertices.southwest.lat,
                        west: zone.preferences.vertices.southwest.lng,
                    },
                });
            } else if (zone.type === ZoneType.POLYLINE) {
                // const iconsetngs = {
                //     path:
                //         'M25.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759' +
                //         'c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z' +
                //         'M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713' +
                //         'v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336' +
                //         'h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805z',
                //     fillColor: '#610ACF', //this.PRIMARY_COLOR,
                //     fillOpacity: 1,
                //     scale: 0.5,
                //     anchor: new google.maps.Point(23, 0),
                // };

                zoneRect = new google.maps.Polyline({
                    path: zone.preferences.vertices,
                    geodesic: true,
                    strokeColor: '#610ACF',
                    strokeWeight: 3,
                    map: this.googleMap.googleMap,
                    // icons: [
                    //     {
                    //         icon: iconsetngs,
                    //         repeat: '100px',
                    //     },
                    // ],
                });
            } else if (zone.type === ZoneType.WIFI) {
                zoneRect = createHTMLMapMarker({
                    latlng: { lat: zone.preferences.center.lat, lng: zone.preferences.center.lng },
                    map: this.googleMap.googleMap,
                    html: `
                    <div id="marker" class="marker">
                        <div class="marker-image-container">
                            <img class="marker-default" src="assets/images/wifi-icon.svg">
                        </div>
                    </div>
                `,
                    isSetToMap: true,
                });
            }

            const infoWindow = new google.maps.InfoWindow({
                content: `<h1 class="zone-title text-ellipsis ${zone.type}" id="infowindow_${zone.id}"">${zone.name}</h1>`,
                disableAutoPan: true,
            });

            let ne: google.maps.LatLng;
            let sw: google.maps.LatLng;

            if (zone.type === ZoneType.POLYGON) {
                const bounds = new google.maps.LatLngBounds();
                zoneRect.getPath().forEach((element) => bounds.extend(element));
                ne = bounds.getNorthEast();
                sw = bounds.getSouthWest();
            } else if (zone.type === ZoneType.RECTANGLE || zone.type === ZoneType.DEVICE_GEOZONE) {
                ne = zoneRect.getBounds().getNorthEast();
                sw = zoneRect.getBounds().getSouthWest();
            } else if (zone.type === ZoneType.POLYLINE) {
                const bounds = new google.maps.LatLngBounds();
                zoneRect.getPath().forEach((element) => bounds.extend(element));
                ne = bounds.getNorthEast();
                sw = bounds.getSouthWest();
            }

            infoWindow.setPosition({
                lat: zone.type === ZoneType.WIFI ? zone.preferences.center.lat : ne.lat(),
                lng: zone.type === ZoneType.WIFI ? zone.preferences.center.lng : (sw.lng() + ne.lng()) / 2,
            });

            infoWindow.open(this.googleMap.googleMap);

            google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
                document
                    .getElementById(`infowindow_${zone.id}`)
                    ?.addEventListener('click', () => this.router.navigate(['/map/places/geo/', zone.id]));
            });

            this.infoWindows.push(infoWindow);
            zoneRect.addListener('click', () =>
                this.zone.run(() => this.router.navigate(['/map/places/geo/', zone.id])),
            );

            return zoneRect;
        });
    }

    initDeviceMarker(device: IDeviceFull | IDeviceByToken): void {
        let latitude: number;
        let longitude: number;
        let age: number;

        if (device['geoLocation']) {
            // IDeviceByToken
            latitude = device['geoLocation'].latitude;
            longitude = device['geoLocation'].longitude;
            age = device['geoLocation'].age;
        } else if (device['location_ping']) {
            // IDeviceFull
            latitude = device['location_ping'].lat;
            longitude = device['location_ping'].lng;
            age = device['location_ping'].age;
        }

        if (!latitude && !longitude) {
            this.store.dispatch(MapActions.showNoLocationModal());
            return;
        }

        const location: ILatLng = { lat: latitude, lng: longitude };

        const marker = createHTMLMapMarker({
            latlng: location,
            map: this.googleMap.googleMap,
            html: this.getMarkerTemplate(device, age),
            isSetToMap: true,
        });

        marker.addListener('click', () => {
            this.zone.run(() => {
                this.store.dispatch(DevicesActions.showDeviceInfo({ device }));
            });
        });

        this.selecteedDeviceCircle = this.getMarkerRadius(device, location);
        this.selecteedDeviceCircle.bindTo('map', marker, 'map');
        this.markers.push(marker);
        this.googleMap.fitBounds(this.selecteedDeviceCircle.getBounds());
    }

    updateSelectedDeviceMarkerPosition(device: IDeviceFull, isDeviceFollowing?: boolean): void {
        if (this.markers[0]) {
            const location: ILatLng = { lat: device.location_ping.lat, lng: device.location_ping.lng };
            this.markers[0]['latlng'] = location;
            this.markers[0]['html'] = this.updateMarkerAge(this.markers[0], device.location_ping.age);
            this.markers[0].setMap(this.googleMap.googleMap);
            this.selecteedDeviceCircle.setCenter(location);
            if (isDeviceFollowing) {
                this.googleMap.googleMap.setCenter(location);
            }
        }
    }

    updateMarkersPositions(devices: IDeviceShort[]) {
        this.markers = devices
            .filter((device) => device.lat && device.lng)
            .map((device: IDeviceShort, i) => {
                let lat = device.lat;
                let lng = device.lng;
                let age = device.age;
                if (device.location_ping) {
                    lat = device.location_ping.lat;
                    lng = device.location_ping.lng;
                    age = device.location_ping.age;
                }
                const marker = createHTMLMapMarker({
                    latlng: new google.maps.LatLng(lat, lng),
                    map: this.googleMap.googleMap,
                    html: this.getMarkerTemplate(device, age),
                    id: device.device_id,
                    isSetToMap: false,
                });

                const circle = this.getMarkerRadius(device, { lat, lng });
                circle.bindTo('map', marker, 'map');

                marker.addListener('click', () => {
                    if (device.allSOSEvents?.length) {
                        this.store.dispatch(
                            DevicesActions.updateDeviceEvent({ events: device.allSOSEvents }),
                        );
                    }
                    this.zone.run(() => this.router.navigate(['/map/devices/', device.device_id]));
                });

                return marker;
            });

        this.markerClusterer?.clearMarkers();
        this.markerClusterer?.addMarkers(this.markers);
        // if (!this.router.url.includes('/places')) {
        //     this.fitBounds(devices, []);
        // }
    }

    updateSelectedDeviceMarkerIcon(device: IDeviceFull) {
        this.markers[0]['html'] = this.getMarkerTemplate(device, device['location_ping'].age);
        this.markers[0].setMap(this.googleMap.googleMap);
        const circleColor = this.getCircleColor(device);
        this.selecteedDeviceCircle.setOptions({
            fillColor: circleColor,
            strokeColor: circleColor,
            radius: this.getCircleSize(device['location_ping'].type),
        });
    }

    updateMarkerAge(marker, age): string {
        const markerHTML = new DOMParser().parseFromString(marker['html'], 'text/html');
        if (markerHTML.getElementById('deviceAge')) {
            markerHTML.getElementById('deviceAge').textContent = `${this.timeSince(age)} ago`;
        }
        return markerHTML.getElementById('marker')?.outerHTML;
    }

    initDeviceZone(zone: IZone): void {
        let zoneRect;
        let bounds = new google.maps.LatLngBounds();

        if (zone.type === ZoneType.POLYGON) {
            zoneRect = new google.maps.Polygon({
                strokeColor: '#FFBD13',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#FFBD13',
                fillOpacity: 0.3,
                editable: true,
                draggable: true,
                map: this.googleMap.googleMap,
                paths: zone.preferences.vertices,
            });
            const path = zoneRect.getPath();
            path.forEach((element) => bounds.extend(element));

            let dragging: boolean;
            zoneRect.addListener('dragstart', () => (dragging = true));
            zoneRect.addListener('dragend', () => {
                dragging = false;
                this.zoneSize.next(this.getZoneSize(zoneRect, zone.type as ZoneType));
            });

            google.maps.event.addListener(zoneRect.getPath(), 'set_at', (event) => {
                if (!dragging) {
                    this.zone.run(() =>
                        this.zoneSize.next(this.getZoneSize(zoneRect, zone.type as ZoneType)),
                    );
                }
            });
            google.maps.event.addListener(zoneRect.getPath(), 'insert_at', (event) => {
                if (!dragging) {
                    this.zone.run(() =>
                        this.zoneSize.next(this.getZoneSize(zoneRect, zone.type as ZoneType)),
                    );
                }
            });

            this.zoneSize.next(this.getZoneSize(zoneRect, zone.type as ZoneType));
        } else if (zone.type === ZoneType.RECTANGLE || zone.type === ZoneType.DEVICE_GEOZONE) {
            zoneRect = new google.maps.Rectangle({
                strokeColor: this.PRIMARY_COLOR,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: this.PRIMARY_COLOR,
                fillOpacity: 0.3,
                map: this.googleMap.googleMap,
                editable: true,
                draggable: true,
                bounds: {
                    north: zone.preferences.vertices.northeast.lat,
                    east: zone.preferences.vertices.northeast.lng,
                    south: zone.preferences.vertices.southwest.lat,
                    west: zone.preferences.vertices.southwest.lng,
                },
            });

            bounds = zoneRect.getBounds();

            zoneRect.addListener('bounds_changed', () => {
                return this.zone.run(() =>
                    this.zoneSize.next(this.getZoneSize(zoneRect, zone.type as ZoneType)),
                );
            });

            this.zoneSize.next(this.getZoneSize(zoneRect, zone.type as ZoneType));
        } else if (zone.type === ZoneType.POLYLINE) {
            zoneRect = this.initRoute(
                zone.preferences.vertices[0],
                zone.preferences.vertices[zone.preferences.vertices.length - 1],
                zone.preferences.wayPointsList,
                zone.preferences.travelMode as google.maps.TravelMode,
            );
        } else if (zone.type === ZoneType.WIFI) {
            const location = { lat: zone.preferences.center.lat, lng: zone.preferences.center.lng };
            zoneRect = createHTMLMapMarker({
                latlng: location,
                map: this.googleMap.googleMap,
                html: `
                <div id="marker" class="marker">
                    <div class="marker-image-container">
                        <img class="marker-default" src="assets/images/wifi-icon.svg">
                    </div>
                </div>
            `,
                isSetToMap: true,
            });

            bounds.extend(new google.maps.LatLng(location));
        }

        this.rectangles.push(zoneRect);
        this.googleMap.fitBounds(bounds);
    }

    initNewZone(zoneType = ZoneType.DEVICE_GEOZONE): void {
        this.zoomIn();
        const rectBounds = this.googleMap.getBounds();
        let zoneRect;

        if (zoneType === ZoneType.RECTANGLE || zoneType === ZoneType.DEVICE_GEOZONE) {
            zoneRect = new google.maps.Rectangle({
                strokeColor: this.PRIMARY_COLOR,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: this.PRIMARY_COLOR,
                fillOpacity: 0.3,
                zIndex: 1000,
                map: this.googleMap.googleMap,
                editable: true,
                draggable: true,
                bounds: rectBounds,
            });

            zoneRect.addListener('bounds_changed', () =>
                this.zone.run(() => this.zoneSize.next(this.getZoneSize(zoneRect, zoneType))),
            );
            this.zoneSize.next(this.getZoneSize(zoneRect, zoneType));
            this.rectangles.push(zoneRect);
        } else if (zoneType === ZoneType.POLYGON) {
            const NE = rectBounds.getNorthEast();
            const SW = rectBounds.getSouthWest();
            const NW = new google.maps.LatLng(NE.lat(), SW.lng());
            const SE = new google.maps.LatLng(SW.lat(), NE.lng());

            zoneRect = new google.maps.Polygon({
                strokeColor: '#FFBD13',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#FFBD13',
                fillOpacity: 0.3,
                zIndex: 1000,
                map: this.googleMap.googleMap,
                editable: true,
                draggable: true,
                paths: [[NE, NW, SW, SE]],
            });

            const bounds = new google.maps.LatLngBounds();
            zoneRect.getPath().forEach((element) => bounds.extend(element));

            let dragging: boolean;
            zoneRect.addListener('dragstart', () => (dragging = true));
            zoneRect.addListener('dragend', () => {
                dragging = false;
                this.zoneSize.next(this.getZoneSize(zoneRect, zoneType));
            });

            google.maps.event.addListener(zoneRect.getPath(), 'set_at', (event) => {
                if (!dragging) {
                    this.zone.run(() => this.zoneSize.next(this.getZoneSize(zoneRect, zoneType)));
                }
            });

            google.maps.event.addListener(zoneRect.getPath(), 'insert_at', (event) => {
                if (!dragging) {
                    this.zone.run(() => this.zoneSize.next(this.getZoneSize(zoneRect, zoneType)));
                }
            });

            this.zoneSize.next(this.getZoneSize(zoneRect, zoneType));
            this.rectangles.push(zoneRect);
        } else if (zoneType === ZoneType.POLYLINE) {
            this.zoneSize.next(this.getZoneSize(null, zoneType));
        }

        this.zoomOut();
    }

    initRoute(
        start: ILatLng,
        end: ILatLng,
        wayPointsList?: google.maps.DirectionsGeocodedWaypoint[],
        travelMode?: google.maps.TravelMode,
    ) {
        this.initRouteCalled = true;
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer({
            draggable: true,
            map: this.googleMap.googleMap,
            panel: document.getElementById('panel') as HTMLElement,
            polylineOptions: {
                strokeColor: '#610ACF',
                strokeWeight: 3,
            },
            routeIndex: 1000,
            markerOptions: {
                icon: 'assets/images/map_point_filled.svg',
            },
        });

        directionsRenderer.addListener('directions_changed', () => {
            const directions = directionsRenderer.getDirections();

            if (!directions.routes.length) {
                this.snackBar.error('no results');
                return;
            }

            if (directions) {
                this.zoneSize.next(this.getZoneSize(directions, ZoneType.POLYLINE));
            }
        });

        directionsService.route(
            {
                origin: start,
                destination: end,
                waypoints: wayPointsList?.map((wp) => ({
                    stopover: false,
                    location: {
                        placeId: wp.place_id,
                    },
                })),
                travelMode: travelMode || google.maps.TravelMode.DRIVING,
            },
            (result: google.maps.DirectionsResult) => {
                directionsRenderer.setDirections(result);
                this.zoneSize.next(this.getZoneSize(result, ZoneType.POLYLINE));
            },
        );

        this.directionsRenderer.push(directionsRenderer);
        return directionsRenderer;
    }

    private computeTotalDistance(result: google.maps.DirectionsResult): number {
        let total = 0;
        const myroute = result.routes[0];

        for (let i = 0; i < myroute.legs.length; i++) {
            total += myroute.legs[i]!.distance!.value;
        }

        return total;
    }

    removeMarkers(): void {
        this.markerClusterer?.removeMarkers(this.markers);
        this.markers?.forEach((marker) => marker.setMap(null));
        this.markers = [];
    }

    removeZones(): void {
        this.rectangles?.forEach((r) => r.setMap(null));
        this.removeInitialZone();
    }

    removeInitialZone(): void {
        this.initRouteCalled = false;
        this.rectangles.forEach((r) => {
            // editable temporary zone
            if (r['zIndex'] === 1000) {
                r.setMap(null);
            }
        });

        this.directionsRenderer.forEach((r) => {
            r.setMap(null);
        });
    }

    removeInfoWindows() {
        this.infoWindows.forEach((iw) => iw.close());
    }

    removeHistory(): void {
        this.polyline?.setMap(null);
    }

    initDeviceHistory(history: IDeviceHistory[]): void {
        const bounds = new google.maps.LatLngBounds();

        const coordinates = history.map((h) => {
            const location = { lat: h.lat, lng: h.lng };
            bounds.extend(new google.maps.LatLng(location));

            let imgUrl: string;
            if (skin.whiteLabel === 'TAGANDTRACK' || skin.whiteLabel === 'TRACKIPET') {
                imgUrl = `assets/images/history/${skin.whiteLabel}/`;
            } else {
                imgUrl = 'assets/images/history/';
            }
            const marker = new google.maps.Marker({
                position: new google.maps.LatLng(location.lat, location.lng),
                icon: {
                    url: h.location_id ? `${imgUrl}history-pin-new.svg` : this.getEventPinIcon(h.alarm_type),
                    scaledSize: new google.maps.Size(24, 24),
                    anchor: new google.maps.Point(10, 10),
                },
            });
            marker.set('id', h.location_id ? h.location_id : h.event_id);

            this.markers.push(marker);

            marker.addListener('click', () => {
                this.zone.run(() => {
                    this.store.dispatch(DevicesActions.showDeviceHistoryInfo({ historyInfo: h }));
                });
            });

            return location;
        });

        this.setMarkerCluster();

        this.polyline = new google.maps.Polyline({
            path: coordinates.reverse(),
            strokeColor: this.PRIMARY_COLOR,
            strokeOpacity: 1.0,
            strokeWeight: 2,
            icons: [
                {
                    repeat: '100px',
                    icon: {
                        path: google.maps.SymbolPath.BACKWARD_OPEN_ARROW,
                    },
                    offset: '100%',
                },
            ],
        });

        this.polyline.setMap(this.googleMap.googleMap!);
        this.googleMap.fitBounds(bounds);
    }

    getAddress(lat: number, lng: number) {
        return this.apiService.getAddress(lat, lng);
    }

    getEventPinIcon(alarmType: number): string {
        const notification = NOTIFICATION_TYPES.find((alarmInfo) => alarmInfo.alarmTypeAsInt === alarmType);
        return `assets/images/notifications/new/${notification.alarm_icon.replace('#', '')}.svg`;
    }

    updateHistoryMarkerIcon(historyItem: IDeviceHistory) {
        const initialZoom = this.googleMap.getZoom();
        const bounds = new google.maps.LatLngBounds();
        this.markers.forEach((marker) => {
            if (historyItem?.location_id === marker.id || historyItem?.event_id === marker.id) {
                marker.setIcon({
                    url: marker.getIcon().url,
                    scaledSize: new google.maps.Size(40, 40),
                    anchor: new google.maps.Point(20, 20),
                });
                marker.set('isSelected', true);
                bounds.extend(new google.maps.LatLng({ lat: historyItem.lat, lng: historyItem.lng }));
            } else {
                if (marker.isSelected) {
                    marker.setIcon({
                        url: marker.getIcon().url,
                        scaledSize: new google.maps.Size(20, 20),
                        anchor: new google.maps.Point(10, 10),
                    });
                }
            }

            this.googleMap.fitBounds(bounds);
            this.googleMap.googleMap.setZoom(initialZoom);
        });
    }

    deselectHistoryPoint() {
        this.markers.forEach((marker) => {
            if (marker.isSelected) {
                marker.setIcon({
                    url: marker.getIcon().url,
                    scaledSize: new google.maps.Size(20, 20),
                    anchor: new google.maps.Point(10, 10),
                });
            }
            marker.setMap(this.googleMap.googleMap);
        });
    }

    private getMarkerTemplate(
        device: IDeviceShort | IDeviceFull | IDeviceByToken | ILocation,
        age: number,
        isVisible = true,
    ): string {
        let deviceName: string;
        let latitude: number;
        let longitude: number;

        if (device['info']) {
            // IDeviceFull
            deviceName = device['info'].nick_name ? device['info'].nick_name : device['info'].device_id;
        } else if (device['geoLocation']) {
            // IDeviceByToken
            deviceName = device['deviceName'] ? device['deviceName'] : device['deviceId'];
        } else if (device['deviceDetail']) {
            deviceName = device['deviceDetail'].deviceName;
        } else {
            // IDeviceShort
            deviceName = device['device_name'] ? device['device_name'] : device['device_id'];
        }

        if (device['geoLocation']) {
            // IDeviceByToken
            latitude = device['geoLocation'].latitude;
            longitude = device['geoLocation'].longitude;
        } else if (device['location_ping']) {
            // IDeviceFull
            latitude = device['location_ping'].lat;
            longitude = device['location_ping'].lng;
        } else {
            latitude = device['lat'];
            longitude = device['lng'];
        }

        let markerType = 'default-marker';
        if (device['allSOSEvents']?.length) {
            markerType = 'red-marker';
        } else if (device['location_ping']) {
            if (
                !device['location_ping']?.is_fast_tracking_enabled &&
                (device['location_ping'].shutdown_reason === 'POWER_BUTTON_SHUTDOWN' ||
                    device['location_ping'].shutdown_reason === 'UNKNOWN' ||
                    device['location_ping'].shutdown_reason === 'UNKNOWN_REASON' ||
                    device['location_ping'].shutdown_reason === 'LOW_BATTERY_SHUTDOWN')
            ) {
                markerType = 'inactive-marker';
            }

            if (device['location_ping'].shutdown_reason === 'RF_OFF') {
                markerType = 'no-movement-marker';
            }

            if (device['location_ping'].shutdown_reason === 'SCHEDULE_SLEEP_SHUTDOWN') {
                markerType = 'sleep-mode-marker';
            }

            if (
                device['location_ping']?.is_fast_tracking_enabled ||
                !device['location_ping'].shutdown_reason
            ) {
                markerType = 'online-mode-marker';
            }
        }

        let isInSleep = device['schedule_sleep_configuration']?.is_currently_in_sleep;
        let wakeUpTime = device['schedule_sleep_configuration']?.scheduled_sleep_wakeup_in_min;
        if (isInSleep) {
            if (wakeUpTime !== null) {
                markerType = 'inactive-marker';
                wakeUpTime = wakeUpTime;
            } else {
                isInSleep = null;
            }
        }
        let refreshIconName = !isInSleep ? 'refresh' : 'refresh-wakeup';

        let lowBattery = '';
        if (device['location_ping']) {
            lowBattery =
                (device['location_ping'].battery && device['location_ping'].battery < 10) ||
                !device['location_ping'].battery ||
                device['location_ping'].shutdown_reason === 'LOW_BATTERY_SHUTDOWN'
                    ? '<img class="low-battery" src="assets/images/low-battery.svg">'
                    : '';
        } else {
            lowBattery =
                (device['battery'] && device['battery'] < 10) || !device['battery']
                    ? '<img class="low-battery" src="assets/images/low-battery.svg">'
                    : '';
        }
        return `
            <div id="marker" class="marker ${!isVisible ? 'hidden' : ''}">
                ${
                    age &&
                    !device['location_ping']?.shutdown_reason &&
                    !device['location_ping']?.is_fast_tracking_enabled
                        ? `<div class="marker-time ${markerType}">
                                ${
                                    !device['isLostPet']
                                        ? `<img class="refresh" src="assets/images/${refreshIconName}.svg">`
                                        : ''
                                }
                                <span id="${!isInSleep ? 'deviceAge' : 'wakeUp'}">${
                              !isInSleep
                                  ? this.timeSince(age) + ' ago'
                                  : 'Awake in ' + this.timeLeft(wakeUpTime)
                          }</span>
                          
                                ${!device['isLostPet'] ? lowBattery : ''}
                            </div>`
                        : ''
                }

                ${
                    device['location_ping']?.is_fast_tracking_enabled && !device['allSOSEvents']?.length
                        ? `<div class="live-tracking"><img src="assets/images/refresh.svg">Live tracking${lowBattery}</div>`
                        : ''
                }

                ${
                    device['location_ping']?.shutdown_reason === 'RF_OFF'
                        ? `<div class="no-movement">No movement mode${lowBattery}</div>`
                        : ''
                }
                
                ${
                    device['location_ping']?.shutdown_reason === 'SCHEDULE_SLEEP_SHUTDOWN'
                        ? `<div class="sleep-mode">Sleeping mode${lowBattery}</div>`
                        : ''
                }

                ${
                    !device['location_ping']?.is_fast_tracking_enabled &&
                    (device['location_ping']?.shutdown_reason === 'POWER_BUTTON_SHUTDOWN' ||
                        device['location_ping']?.shutdown_reason === 'UNKNOWN' ||
                        device['location_ping']?.shutdown_reason === 'UNKNOWN_REASON' ||
                        device['location_ping']?.shutdown_reason === 'LOW_BATTERY_SHUTDOWN')
                        ? `<div class="shut-down">Shut down${lowBattery}</div>`
                        : ''
                }

                <div class="marker-image-container">
                    ${
                        device['location_ping'] || device['location']
                            ? `<div class="connection-type ${markerType}">
                                    <img src="white-labels/${skin.whiteLabel}/${(
                                  device['location_ping'] || device['location']
                              ).type.toLowerCase()}.svg">
                                </div>`
                            : ''
                    }
                    <img class="marker-default" src="white-labels/${skin.whiteLabel}/${markerType}.svg">
                    <img class="m-image ${!device['icon_url'] ? markerType : ''} ${
            isInSleep ? 'inactive-marker' : ''
        }"
                        src="${this.getMarkerIcon(device)}"
                        alt="${deviceName}">
                </div>

                ${
                    this.isShowDeviceName
                        ? `<div class="marker-name" title="${deviceName}">${deviceName}</div>`
                        : ''
                }
            </div>
        `;
    }

    private getUserPin(isVisible = true): string {
        return `<div class="user-pin ${!isVisible ? 'hidden' : ''}"></div>`;
    }

    private getMarkerIcon(device: IDeviceShort | IDeviceFull | IDeviceByToken | ILocation): string {
        let markerIconUrl: string;
        let deviceIconUrl: string;
        let deviceIconID: string;

        if (device['info']) {
            deviceIconUrl = device['info'].icon_url;
            deviceIconID = device['info'].icon_id;
        } else if (device['deviceDetail']) {
            deviceIconUrl = device['deviceDetail'].iconUrl;
        } else {
            deviceIconUrl = device['icon_url'];
            deviceIconID = device['icon_id'];
        }

        if (deviceIconUrl) {
            markerIconUrl = deviceIconUrl;
        } else {
            if (skin.whiteLabel === 'TRACKIPET') {
                markerIconUrl = DeviceMarker.Pet;
            } else {
                switch (deviceIconID?.toString()) {
                    case '2':
                        markerIconUrl = DeviceMarker.Watch;
                        break;
                    case '3':
                        markerIconUrl = DeviceMarker.TrackiPro;
                        break;
                    case '4':
                        markerIconUrl = DeviceMarker.Universal;
                        break;
                    case '5':
                        markerIconUrl = DeviceMarker.Guardian;
                        break;
                    case '6':
                        markerIconUrl = DeviceMarker.Mini;
                        break;
                    case '7':
                        markerIconUrl = DeviceMarker.Travel;
                        break;
                    case '9':
                        markerIconUrl = DeviceMarker.TrackimoPlus;
                        break;
                    case '10':
                        markerIconUrl = DeviceMarker.Male;
                        break;
                    case '11':
                        markerIconUrl = DeviceMarker.Female;
                        break;
                    case '12':
                        markerIconUrl = DeviceMarker.Etoll;
                        break;
                    case '14':
                        markerIconUrl = DeviceMarker.Pet;
                        break;
                    default:
                        markerIconUrl = DeviceMarker.Universal;
                }
            }
        }

        return markerIconUrl;
    }

    private timeLeft(minutes: number): string {
        if (minutes > 60) {
            let interval = minutes / 60;
            return Math.floor(interval) + 'h';
        }
        return minutes + 'm';
    }

    public timeSince(seconds: number): string {
        let interval = seconds / 31536000;
        if (interval > 1) {
            return Math.floor(interval) + 'y';
        }

        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + 'mo';
        }

        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + 'd';
        }

        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + 'h';
        }

        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + 'min';
        }
        return Math.floor(seconds) + 's';
    }

    private getZoneSize(zone, zoneType = ZoneType.RECTANGLE): IZoneSize {
        let bounds: google.maps.LatLngBounds;
        let vertices;
        let distance: number;
        let travelMode;

        if (zoneType === ZoneType.POLYGON) {
            bounds = new google.maps.LatLngBounds();
            zone.getPath().forEach((element) => bounds.extend(element));
            vertices = zone
                .getPath()
                .getArray()
                .map((e) => ({ lat: e.lat(), lng: e.lng() }));
        } else if (zoneType === ZoneType.RECTANGLE || zone?.type === ZoneType.DEVICE_GEOZONE) {
            bounds = zone.getBounds();

            vertices = {
                southwest: { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() },
                northeast: { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() },
            };
        } else if (zoneType === ZoneType.POLYLINE) {
            bounds = new google.maps.LatLngBounds();
            if (zone?.routes[0]) {
                vertices = zone?.routes[0].overview_path.map((e) => ({ lat: e.lat(), lng: e.lng() }));
                distance = zone ? this.computeTotalDistance(zone) : 0;
                travelMode = zone.request.travelMode;
            }
        }

        const height = google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(bounds.toJSON().north, bounds.toJSON().east),
            new google.maps.LatLng(bounds.toJSON().south, bounds.toJSON().east),
        );

        const width = google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(bounds.toJSON().north, bounds.toJSON().east),
            new google.maps.LatLng(bounds.toJSON().north, bounds.toJSON().west),
        );

        return {
            height: Math.round(height),
            width: Math.round(width),
            distance,
            type: zoneType,
            preferences: {
                trigger: 'BOTH',
                vertices,
                wayPointsList: zone?.geocoded_waypoints,
                travelMode,
            },
        };
    }
    // @TODO Removed if not needed
    private _getAddress(lat: number, lng: number, callback): void {
        const geoCoder = new google.maps.Geocoder();
        geoCoder.geocode({ location: { lat, lng } }, (results, status) => {
            let address: string;
            if (status === 'OK') {
                if (results[0]) {
                    address = results[0].formatted_address;
                } else {
                    address = 'Address not found';
                }
            } else {
                address = 'Address not found';
            }

            callback(address);
        });
    }

    private getMarkerRadius(device, location): google.maps.Circle {
        let connectionType = (device as IDeviceFull).location_ping?.type;
        let circleColor = this.getCircleColor(device);
        // For device by token
        if (device.geoLocation) {
            connectionType = device.geoLocation.type;
        }
        // For locations
        if (device.deviceLastLocation) {
            connectionType = device.deviceLastLocation.type;
        }
        const circle = new google.maps.Circle({
            strokeColor: circleColor,
            strokeOpacity: 0.6,
            strokeWeight: 2,
            fillColor: circleColor,
            fillOpacity: 0.3,
            center: location,
            radius: this.getCircleSize(connectionType),
        });

        return circle;
    }

    private getCircleColor(device: IDeviceFull): string {
        let color: string;
        if (device.allSOSEvents?.length) {
            color = this.SOS_MARKER_COLOR;
        } else if (
            !device.location_ping?.is_fast_tracking_enabled &&
            (device.location_ping?.shutdown_reason === 'POWER_BUTTON_SHUTDOWN' ||
                device.location_ping?.shutdown_reason === 'UNKNOWN' ||
                device.location_ping?.shutdown_reason === 'UNKNOWN_REASON' ||
                device.location_ping?.shutdown_reason === 'LOW_BATTERY_SHUTDOWN')
        ) {
            color = this.INACTIVE_MARKER_COLOR;
        } else if (device.location_ping?.shutdown_reason === 'RF_OFF') {
            color = this.NO_MOVEMENT_MARKER_COLOR;
        } else if (device.location_ping?.shutdown_reason === 'SCHEDULE_SLEEP_SHUTDOWN') {
            color = this.SLEEP_MODE_COLOR;
        } else if (device.location_ping?.is_fast_tracking_enabled || !device.location_ping?.shutdown_reason) {
            color = this.ONLINE_MODE_COLOR;
        } else {
            color = this.PRIMARY_COLOR;
        }

        return color;
    }

    private getCircleSize(connectionType): number {
        let index = 0;
        if (connectionType === 'gps' || connectionType === 'GPS') {
            index = 20;
        } else if (connectionType === 'wifi' || connectionType === 'WIFI') {
            index = 100;
        } else if (connectionType === 'gsm' || connectionType === 'GSM') {
            index = 1000;
        }
        return index;
    }

    private setMarkerCluster(): void {
        const r = 35;
        const r0 = Math.round(r * 0.6);
        const w = 70;

        const clusterIcon = window.btoa(`
            <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 ${w} ${w}">
                <circle cx="${r}" cy="${r}" r="${r0}" fill="${skin.primaryColor}" />
            </svg>
        `);

        this.markerClusterer = new markerClusterer.MarkerClusterer({
            map: this.googleMap.googleMap,
            markers: this.markers,
            renderer: {
                render({ count, position }) {
                    return new google.maps.Marker({
                        position,
                        icon: {
                            url: `data:image/svg+xml;base64,${clusterIcon}`,
                            scaledSize: new google.maps.Size(w, w),
                            anchor: new google.maps.Point(35, 35),
                        },
                        label: {
                            text: String(count),
                            color: '#fff',
                            fontSize: '18px',
                            fontWeight: 'normal',
                        },
                        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                    });
                },
            },
        });
    }

    addLocationsCluster(locations: ILocation[]): void {
        this.markers = locations.map((location) => {
            let lat = location.deviceLastLocation.latitude;
            let lng = location.deviceLastLocation.longitude;

            const marker = createHTMLMapMarker({
                latlng: new google.maps.LatLng(lat, lng),
                map: this.googleMap.googleMap,
                html: this.getMarkerTemplate(location, location.deviceLastLocation.updatedAgo),
                id: location.deviceId,
                isSetToMap: false,
            });

            const circle = this.getMarkerRadius(location, { lat, lng });
            circle.bindTo('map', marker, 'map');
            return marker;
        });

        this.setMarkerCluster();
    }
}

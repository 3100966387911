import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { LocaleService } from 'src/app/store/services';
import { COUNTRIES, NUMBERS_ONLY } from 'src/app/store/constants';

@Component({
    selector: 'app-phone-input',
    templateUrl: './phone-input.component.html',
    styleUrls: ['./phone-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneInputComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
    @Input() isDisabled = false;
    @Input() isRequired = false;
    @Input() set preselectedPhoneData(value: any) {
        this.phoneNumb.patchValue(value?.phoneNumber);
        this._preselectedPhoneData = value;
        this.setInitialValue();
    }
    private _preselectedPhoneData: any;
    protected countryCodes: (string | number | string[])[][] = COUNTRIES;
    public countryCtrl: FormControl = new FormControl();
    public phoneNumb: FormControl;
    public countryFilterCtrl: FormControl = new FormControl();
    public filteredCountryCodes: ReplaySubject<(string | number | string[])[][]> = new ReplaySubject<
        (string | number | string[])[][]
    >(1);
    public currentCountryCodesInfo: any;

    protected _onDestroy = new Subject<void>();

    constructor(private localeService: LocaleService, private cdRef: ChangeDetectorRef) {
        if (this.isRequired) {
            this.phoneNumb = new FormControl('', [Validators.required, Validators.pattern(NUMBERS_ONLY)]);
        } else {
            this.phoneNumb = new FormControl('', [Validators.pattern(NUMBERS_ONLY)]);
        }
    }

    ngOnInit(): void {
        this.countryCtrl.reset({ value: '', disabled: this.isDisabled });
        this.phoneNumb.reset({ value: '', disabled: this.isDisabled });

        if (this._preselectedPhoneData && Object.keys(this._preselectedPhoneData).length) {
            if (this._preselectedPhoneData.phoneCountryCode) {
                this.currentCountryCodesInfo = this.localeService.getCurrentCountryInfoByCountryCode(
                    this._preselectedPhoneData.phoneCountryCode,
                );
            } else {
                this.currentCountryCodesInfo = this.localeService.getCurrentCountryInfoByCode(
                    this._preselectedPhoneData.countryCode,
                );
            }
        } else {
            this.currentCountryCodesInfo = this.localeService.getCurrentCountryInfo();
        }

        this.countryCtrl.setValue(this.currentCountryCodesInfo);
        this.filteredCountryCodes.next(this.countryCodes.slice());

        this.countryFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => this.filterCountryCodes());

        this.countryCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((countryValue) => {
            this.currentCountryCodesInfo = countryValue;
        });
    }

    ngAfterViewInit(): void {
        this.setInitialValue();
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    /**
     * Sets the initial value after the filteredBanks are loaded initially
     */
    protected setInitialValue() {
        if (this._preselectedPhoneData && Object.keys(this._preselectedPhoneData).length) {
            this.phoneNumb.patchValue(this._preselectedPhoneData.phoneNumber);
            this.countryCtrl.setValue(this.currentCountryCodesInfo);
        } else {
            this.filteredCountryCodes.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
                // setting the compareWith property to a comparison function
                // triggers initializing the selection according to the initial value of
                // the form control (i.e. _initializeSelection())
                // this needs to be done after the filteredBanks are loaded initially
                // and after the mat-option elements are available
                this.singleSelect.compareWith = (
                    a: (string | number | string[])[][],
                    b: (string | number | string[])[][],
                ) => a && b && a[1] === b[1];
            });
        }
    }

    validatePhoneForm(): void {
        this.phoneNumb.markAsTouched();
        this.cdRef.markForCheck();
    }

    enableInputs(): void {
        this.countryCtrl.enable();
        this.phoneNumb.enable();
    }

    disableInputs(): void {
        this.countryCtrl.disable();
        this.phoneNumb.disable();
    }

    protected filterCountryCodes() {
        if (!this.countryCodes) {
            return;
        }

        let search = this.countryFilterCtrl.value;
        if (!search) {
            this.filteredCountryCodes.next(this.countryCodes.slice());
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredCountryCodes.next(
            this.countryCodes.filter((country) => {
                const countryCode = `+${country[2]}`;
                return (
                    country[0].toString().toLowerCase().includes(search) ||
                    countryCode.toString().toLowerCase().includes(search)
                );
            }),
        );
    }
}

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthActions } from 'src/app/store/actions/auth.actions';
import { StatusLabelActions } from 'src/app/store/actions/status-label.actions';
import { UserActions } from 'src/app/store/actions/user.actionsts';
import { StatusLabelType, VERIFICATION_CODE_REX_EXP } from 'src/app/store/constants/common.constants';
import { IStatusLabel } from 'src/app/store/interfaces';
import { selectIsStatusLabel } from 'src/app/store/selectors/status.selectors';
import { ApiService } from 'src/app/store/services';
import { IAppState } from 'src/app/store/state/app.state';
import { TranslateService } from '@ngx-translate/core';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-login-auth-code',
    templateUrl: './login-auth-code.component.html',
    styleUrls: ['./login-auth-code.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginAuthCodeComponent implements OnInit, OnDestroy {
    userEmail: string;
    userToken: string;
    verifyAccountError$: Observable<IStatusLabel>;
    authLoginForm: FormGroup = this.formBuilder.group({
        code: ['', [Validators.required, Validators.pattern(VERIFICATION_CODE_REX_EXP)]],
        rememberMe: [true],
    });
    private destroyed$ = new Subject<void>();

    constructor(
        private formBuilder: FormBuilder,
        private store: Store<IAppState>,
        private route: ActivatedRoute,
        private router: Router,
        private apiService: ApiService,
        private translate: TranslateService,
    ) {}

    ngOnInit(): void {
        this.verifyAccountError$ = this.store.select(selectIsStatusLabel);

        this.route.queryParams.subscribe((params) => {
            if (params.email && params.userToken) {
                this.userEmail = params.email;
                this.userToken = params.userToken;
            } else {
                this.router.navigate(['/']);
            }
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();

        this.store.dispatch(StatusLabelActions.hideStatusLabel());
    }

    resendEmailLink() {
        const resendData = {
            client_id: skin.clientId,
            user_token: this.userToken,
            whitelabel: skin.whiteLabel,
        };

        this.store.dispatch(UserActions.resendLink({ resendData }));
    }

    onSubmit(isValid: boolean) {
        this.authLoginForm.markAllAsTouched();
        if (isValid) {
            const payload = {
                client_id: skin.clientId,
                remember_code: this.authLoginForm.value.rememberMe,
                remember_me: false,
                user_token: this.userToken,
                verification_code: this.authLoginForm.value.code,
            };

            this.apiService
                .verifyAccount(payload)
                .pipe(takeUntil(this.destroyed$))
                .subscribe({
                    next: () =>
                        this.store.dispatch(AuthActions.loginByVerificationCode({ redirectPageURL: '/map' })),
                    error: ({ error }) => {
                        if (error['message_key'] === 'exception_account_locked') {
                            this.store.dispatch(AuthActions.loginError({ error }));
                        } else {
                            this.store.dispatch(
                                StatusLabelActions.showStatusLabel({
                                    statusLabel: {
                                        status: error.message_key
                                            ? this.translate.instant(error.message_key)
                                            : error.message,
                                        labelType: StatusLabelType.WARNING,
                                    },
                                }),
                            );
                        }
                    },
                });
        }
    }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { LocalStorageService } from '../local-storage.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
    constructor(private router: Router, private localStorage: LocalStorageService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.url.includes('amazonaws') && !request.url.includes('locationiq')) {
            request = request.clone({
                setHeaders: {
                    Authorization: localStorage.getItem('token') || '',
                },
            });
        }

        return next.handle(request).pipe(
            catchError((err, caught) => {
                if (err.status === 401) {
                    if (
                        err.error.message_key !== 'exception_user_verification_code_invalid' &&
                        err.error.message_key !== 'exception_user_invalid_password'
                    ) {
                        this.handleAuthError();
                    }
                    return throwError(err);
                }
                throw err;
            }),
        );
    }
    private handleAuthError() {
        this.localStorage.remove('token');
        this.localStorage.remove('state');
        this.localStorage.remove('PingInterval-devices');
        this.localStorage.remove('PingInterval-sub-accounts');
        this.localStorage.remove('whitelabel');
        this.localStorage.remove(`Fast-Traking-Started`);
        this.router.navigate(['/']);
    }
}

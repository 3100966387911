import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-activation-code-dialog',
    templateUrl: './activation-code-dialog.component.html',
    styleUrls: ['./activation-code-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivationCodeDialogComponent implements OnInit {
    activationCodeInput = new FormControl(null, [Validators.required]);

    constructor(
        public dialogRef: MatDialogRef<ActivationCodeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {}

    ngOnInit(): void {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    applyCode(activationCode?: string) {
        this.dialogRef.close(activationCode);
    }
}

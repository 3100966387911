import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { selectUser } from '../../store/selectors/user.selector';
import { takeUntil } from 'rxjs/operators';
import { IUser } from '../../store/interfaces';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';

@Component({
    selector: 'app-emergancy-info-dialog',
    templateUrl: './overwatch-emergancy-info-dialog.component.html',
    styleUrls: ['./overwatch-emergancy-info-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverwatchEmergancyInfoDialogComponent implements OnInit, OnDestroy {
    userPhoneNumber: string;
    private destroyed$ = new Subject<void>();
    constructor(
        public dialogRef: MatDialogRef<OverwatchEmergancyInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private store: Store<IAppState>,
    ) {}

    ngOnInit() {
        this.store
            .select(selectUser)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((user: IUser) => (this.userPhoneNumber = user.phone));
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    close(): void {
        this.dialogRef.close(true);
    }
}

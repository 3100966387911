import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthActions } from '../actions/auth.actions';
import { StatusLabelActions } from '../actions/status-label.actions';
import { UserActions } from '../actions/user.actionsts';
import { API_VERSION_1, BASE_REDIRECT_URL, BASE_URL } from '../constants';
import {
    DATE_FORMATS,
    OWL_DATE_FORMAT_PORVIDER,
    StatusLabelType,
    USER_FEATURES_LIST,
    USER_FEATURES_OPTIONS_TYPE,
} from '../constants/common.constants';
import { ILogin, IResetPassword, ISigupResendLink } from '../interfaces';
import { IUser, IUserSettings } from '../interfaces/user.interface';
import {
    ApiService,
    DateFormatService,
    FeatureToggleService,
    GoogleMapService,
    LocalStorageService,
} from '../services';
import { Action, Store } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { selectUser } from '../selectors/user.selector';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '../services/snackbar.service';
import { skin } from 'src/white-labels';
import { environment } from 'src/environments/environment';
import { SocialAuthService } from 'angularx-social-login';

@Injectable()
export class UserEffects {
    private loginRedirectURL: string;
    private userLoginData: ILogin;

    login$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.login),
                map((action) => action.userData),
                tap((userData: ILogin) => (this.userLoginData = userData)),
                switchMap((userData: ILogin) => {
                    let user: IUser;
                    return this.apiService.login(userData).pipe(
                        mergeMap(() =>
                            this.apiService.loginAuth({
                                client_id: skin.clientId,
                                redirect_uri: `${BASE_REDIRECT_URL}/${BASE_URL}/internal/${API_VERSION_1}/oauth_redirect`,
                                response_type: 'code',
                                scope: 'locations,notifications,devices,accounts,settings,geozones',
                            }),
                        ),
                        mergeMap((res: any) =>
                            this.apiService.loginToken({
                                client_id: skin.clientId,
                                client_secret: skin.clientSecret,
                                code: res.code,
                            }),
                        ),
                        tap((res: any) => this.localStorage.setItem('token', `Bearer ${res.access_token}`)),
                        mergeMap(() => this.apiService.getUser()),
                        tap((inUser) => (user = inUser)),
                        mergeMap(() => this.apiService.getAccountSettings(user.account_id)),
                        tap((userSettings: IUserSettings) => {
                            user.settings = userSettings;
                            this.dateFormatService.dateFormat = user?.preferences?.date_format;
                            user.subAccountEnabled = userSettings.colors?.some(
                                (item) => item.color === 'SUB_ACCOUNT_ENABLED',
                            );
                            user.isBlockSubAccountActions =
                                userSettings.is_sub_account_action_blocked_by_parent;
                        }),
                        map(() => AuthActions.loginSuccess({ response: user })),
                        catchError(({ error }) => {
                            if (
                                error.message_key === 'exception_user_verification_notVerified' ||
                                error.message_key === 'exception_account_locked'
                            ) {
                                return of(AuthActions.loginError({ error }));
                            } else {
                                return of(
                                    StatusLabelActions.showStatusLabel({
                                        statusLabel: {
                                            status: error.message_key
                                                ? this.translate.instant(error.message_key)
                                                : error.message,
                                            statusCode: error.message_key,
                                            labelType: StatusLabelType.WARNING,
                                        },
                                    }),
                                );
                            }
                        }),
                    );
                }),
            ) as Observable<Action>,
    );

    loginAccountLockedRedirect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.loginError),
                filter(
                    ({ error }) =>
                        // TODO check for error object structure
                        error['message_key'] || error.error.message_key === 'exception_account_locked',
                ),
                tap(({ error }) => {
                    this.router.navigate(['/account-locked'], {
                        state: { unblockTime: error['seconds_to_unblock'] },
                    });
                }),
            ),
        { dispatch: false },
    );

    loginErrorRedirect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.loginError),
                filter(({ error }) => error['message_key'] === 'exception_user_verification_notVerified'),
                tap(({ error }) =>
                    this.router.navigate(['/login-verification'], {
                        queryParams: {
                            userToken: error['extras'].user_token,
                            email: this.userLoginData.username,
                        },
                    }),
                ),
            ),
        { dispatch: false },
    );

    loginRedirect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.loginSuccess),
                tap(() => this.router.navigate(['/map'])),
            ),
        { dispatch: false },
    );

    loginByVerificationCode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loginByVerificationCode),
            tap((action) => (this.loginRedirectURL = action.redirectPageURL)),
            switchMap(() => {
                let user: IUser;
                return this.apiService
                    .loginAuth({
                        client_id: skin.clientId,
                        redirect_uri: `${BASE_REDIRECT_URL}/${BASE_URL}/internal/${API_VERSION_1}/oauth_redirect`,
                        response_type: 'code',
                        scope: 'locations,notifications,devices,accounts,settings,geozones',
                    })
                    .pipe(
                        mergeMap((res: any) =>
                            this.apiService.loginToken({
                                client_id: skin.clientId,
                                client_secret: skin.clientSecret,
                                code: res.code,
                            }),
                        ),
                        tap((res: any) => this.localStorage.setItem('token', `Bearer ${res.access_token}`)),
                        mergeMap(() => this.apiService.getUser()),
                        tap((inUser) => (user = inUser)),
                        mergeMap((user) => this.apiService.getAccountSettings(user.account_id)),
                        tap((userSettings: IUserSettings) => (user.settings = userSettings)),
                        map(() => AuthActions.loginByVerificationCodeSuccess({ response: user })),
                        catchError(({ error }) =>
                            of(
                                StatusLabelActions.showStatusLabel({
                                    statusLabel: {
                                        status: error.message_key
                                            ? this.translate.instant(error.message_key)
                                            : error.message,
                                        labelType: StatusLabelType.WARNING,
                                    },
                                }),
                            ),
                        ),
                    );
            }),
        ),
    );

    loginByVerificationCodeSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.loginByVerificationCodeSuccess),
                tap(() => this.router.navigate([this.loginRedirectURL])),
            ),
        { dispatch: false },
    );

    forgotPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.forgotPassword),
            switchMap((action) =>
                this.apiService.resetPassword({ email: action.email }).pipe(
                    map((response) =>
                        AuthActions.forgotPasswordSuccess({ ...response, email: action.email }),
                    ),
                    catchError((error) => of(AuthActions.forgotPasswordError({ error }))),
                ),
            ),
        ),
    );

    forgotPasswordRedirect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.forgotPasswordSuccess),
                tap(({ email }) => {
                    this.router.navigate(['/forgot-password-verification'], { queryParams: { email } });
                }),
            ),
        { dispatch: false },
    );

    resetPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.resetPassword),
            map((action) => action.passwordData),
            switchMap((passwordData: IResetPassword) => {
                return this.apiService.resetPassword(passwordData).pipe(
                    map((response) => AuthActions.resetPasswordSuccess(response)),
                    catchError((error) => of(AuthActions.resetPasswordError({ error }))),
                );
            }),
        ),
    );

    resetPasswordSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.resetPasswordSuccess),
                tap(() => this.router.navigate(['/forgot-password-success'])),
            ),
        { dispatch: false },
    );

    getUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.getUser),
            switchMap(() => {
                let user: IUser;
                return this.apiService.getUser().pipe(
                    tap((inUser) => (user = inUser)),
                    mergeMap(() => this.apiService.getAccountSettings(user.account_id)),
                    map((userSettings: IUserSettings) => {
                        user.settings = userSettings;
                        this.dateFormatService.dateFormat = user?.preferences?.date_format;
                        user.subAccountEnabled = userSettings.colors?.some(
                            (item) => item.color === 'SUB_ACCOUNT_ENABLED',
                        );
                        user.isBlockSubAccountActions = userSettings.is_sub_account_action_blocked_by_parent;
                        return UserActions.getUserSuccess({ response: user });
                    }),
                    catchError((error) => of(UserActions.getUserError({ error }))),
                );
            }),
        ),
    );

    getUserSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UserActions.getUserSuccess, AuthActions.loginSuccess),
                map((action) => action.response),
                tap((user: IUser) => {
                    const language = user.preferences.language;
                    if (this.localStorage.getItem('language') !== language) {
                        this.localStorage.setItem('language', language);
                        this.translate.use(language);
                    }

                    OWL_DATE_FORMAT_PORVIDER.useValue = {
                        ...OWL_DATE_FORMAT_PORVIDER.useValue,
                        parseInput: `${DATE_FORMATS[user.preferences.date_format].toUpperCase()} HH:mm`,
                        fullPickerInput: `${DATE_FORMATS[user.preferences.date_format].toUpperCase()} HH:mm`,
                    };

                    if (user.preferences.hasOwnProperty('show_tracker_name')) {
                        this.googleMapService.isShowDeviceName = user.preferences.show_tracker_name;
                    }
                    if (
                        user.account_features &&
                        Object.values(user.account_features).some(
                            (fearure) =>
                                fearure === USER_FEATURES_OPTIONS_TYPE.OPTIONAL ||
                                fearure === USER_FEATURES_OPTIONS_TYPE.ENABLED,
                        )
                    ) {
                        this.featureToggleService.initFeaturesData(user.account_features);
                    } else {
                        USER_FEATURES_LIST.forEach((feature) => {
                            this.localStorage.remove(feature.toUpperCase());
                        });
                    }
                }),
            ),
        { dispatch: false },
    );

    updateUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateUser),
            map((action) => action.userData),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([userData, user]) =>
                this.apiService.setUserPreferences(user.account_id, userData).pipe(
                    map(() => UserActions.updateUserSuccess()),
                    catchError((error) => of(UserActions.updateUserError({ error }))),
                ),
            ),
        ),
    );

    updateUserPhoto$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateUserPhoto),
            map((action) => action.userPhoto),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([userData, user]) =>
                this.apiService.updateUserPhoto(user.account_id, userData).pipe(
                    map(() => UserActions.updateUserPhotoSuccess()),
                    catchError((error) => of(UserActions.updateUserPhotoError({ error }))),
                ),
            ),
        ),
    );

    updateUserSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UserActions.updateUserSuccess),
                tap(() => this.store.dispatch(UserActions.getUser())),
            ),
        { dispatch: false },
    );

    updateUserError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UserActions.updateUserError),
                tap(({ error }) => this.snackBar.error(error.error?.message_key)),
            ),
        { dispatch: false },
    );

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.logout, UserActions.getUserError),
            tap(() => {
                this.localStorage.remove('token');
                this.localStorage.remove('state');
                this.localStorage.remove('PingInterval-devices');
                this.localStorage.remove('PingInterval-sub-accounts');
                this.localStorage.remove('whitelabel');
                this.localStorage.remove(`Fast-Traking-Started`);
                this.authService.signOut().then(() => {});
            }),
            switchMap(() =>
                this.apiService.logout().pipe(
                    map(() => AuthActions.logoutSuccess()),
                    catchError((error) => of(AuthActions.logoutError({ error }))),
                ),
            ),
        ),
    );

    logoutSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.logoutSuccess),
                tap(() => {
                    this.router.navigate(['/']);
                }),
            ),
        { dispatch: false },
    );

    resendLink$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.resendLink),
            map((action) => action.resendData),
            switchMap((resendData: ISigupResendLink) =>
                this.apiService.resendLink(resendData).pipe(
                    map(() => UserActions.resendLinkSuccess({ msg: this.translate.instant('SENT') })),
                    catchError(({ error }) =>
                        of(
                            StatusLabelActions.showStatusLabel({
                                statusLabel: {
                                    status: error.message_key
                                        ? this.translate.instant(error.message_key)
                                        : error.message,
                                    labelType: StatusLabelType.WARNING,
                                },
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    setDeviceSleepModeSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UserActions.resendLinkSuccess),
                tap((resonse) => this.snackBar.success(resonse.msg)),
            ),
        { dispatch: false },
    );

    // login with Google and Facebook
    loginWithSocialNetwork$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loginWithSocial),
            map((action) => action),
            switchMap((data) => {
                let user: IUser;
                return this.apiService.signInWithSocialNetwork(data.userData, data.provider).pipe(
                    tap((res: any) => this.localStorage.setItem('token', `Bearer ${res.access_token}`)),
                    mergeMap(() => this.apiService.getUser()),
                    tap((inUser) => (user = inUser)),
                    mergeMap(() => this.apiService.getAccountSettings(user.account_id)),
                    tap((userSettings: IUserSettings) => {
                        user.settings = userSettings;
                        this.dateFormatService.dateFormat = user?.preferences?.date_format;
                        user.subAccountEnabled = userSettings.colors?.some(
                            (item) => item.color === 'SUB_ACCOUNT_ENABLED',
                        );
                        user.isBlockSubAccountActions = userSettings.is_sub_account_action_blocked_by_parent;
                    }),
                    map(() => AuthActions.loginSuccess({ response: user })),
                    catchError(({ error }) => {
                        if (
                            error.message_key === 'exception_user_verification_notVerified' ||
                            error.message_key === 'exception_account_locked'
                        ) {
                            return of(AuthActions.loginError({ error }));
                        } else {
                            return of(
                                StatusLabelActions.showStatusLabel({
                                    statusLabel: {
                                        status: error.message_key
                                            ? this.translate.instant(error.message_key)
                                            : error.message,
                                        labelType: StatusLabelType.WARNING,
                                    },
                                }),
                            );
                        }
                    }),
                );
            }),
        ),
    );

    deleteMyAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.deleteAccount),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([, user]) =>
                this.apiService.deleteMyAccount(user.email).pipe(
                    map(() => UserActions.deleteAccountSuccess({ accountID: user.account_id })),
                    catchError(({ error }) => of(UserActions.deleteAccountError({ error }))),
                ),
            ),
        ),
    );

    deleteMyAccountSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(UserActions.deleteAccountSuccess),
                map((action) => action.accountID),
                tap((accountID: number) => this.store.dispatch(AuthActions.logout({ accountID: accountID }))),
                tap(() =>
                    this.snackBar.success(
                        this.translate.instant('ACCOUNT') + ' ' + this.translate.instant('has_been_deleted'),
                    ),
                ),
            );
        },
        { dispatch: false },
    );

    deleteMyAccountError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UserActions.deleteAccountError),
                tap(({ error }) =>
                    this.snackBar.error(
                        error['message_key'] ? this.translate.instant(error['message_key']) : error.message,
                    ),
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private apiService: ApiService,
        private authService: SocialAuthService,
        private router: Router,
        private store: Store<IAppState>,
        private snackBar: SnackbarService,
        private translate: TranslateService,
        private localStorage: LocalStorageService,
        private googleMapService: GoogleMapService,
        private dateFormatService: DateFormatService,
        private featureToggleService: FeatureToggleService,
    ) {}
}

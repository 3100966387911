import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
    IActivatePlan,
    IActivatePrepaidPlan,
    IBrainTreeDropInTokenPayload,
    IContactAssignments,
    IDeviceByToken,
    IDeviceFull,
    IDeviceLocationEvent,
    IDevicePreferences,
    IDevicesCount,
    IDevicesSubscriptionRequest,
    IDeviceUsage,
    IEvent,
    IEventsResponse,
    IFPISettings,
    IIncreaseSMSPlan,
    IPendingFields,
    IPetInfo,
    IPlan,
    IRenewPlanByCouponPayload,
    IRenewPlanPayload,
    IRenewPrepaidPlanPayload,
    IShareDeviceLocationRequest,
    IShareDeviceLocationResponse,
    ISubscription,
    IUser,
    IZone,
} from '../interfaces';
import { IDeviceHistory, IHistoryRequest } from '../interfaces/history.interface';

const prefix = '[Devices API]';

const getDevices = createAction(`${prefix} Get Devices`);
const getDevicesSuccess = createAction(`${prefix} Get Devices Success`, props<{ response: any }>());
const getDevicesError = createAction(`${prefix} Get Devices Error`, props<{ error: HttpErrorResponse }>());

const clearDevices = createAction(`${prefix} Clear Devices`);

const getDeviceByID = createAction(`${prefix} Get Device By ID`, props<{ deviceID: number }>());
const getDeviceByIDSuccess = createAction(
    `${prefix} Get Device By ID Success`,
    props<{ device: any; user: IUser }>(),
);
const getDeviceByIDError = createAction(
    `${prefix} Get Device By ID Error`,
    props<{ error: HttpErrorResponse }>(),
);

const removeSelectedDevice = createAction(`${prefix} Get Device By ID Error`);

const getDeviceDetailsByToken = createAction(
    `${prefix} Get Device By Token`,
    props<{ devices: IDeviceByToken[] }>(),
);
const getDeviceDetailsByTokenSuccess = createAction(
    `${prefix} Get Device By Token Success`,
    props<{ devices: IDeviceByToken[] }>(),
);
const getDeviceDetailsByTokenError = createAction(
    `${prefix} Get Device By Token Error`,
    props<{ error: HttpErrorResponse }>(),
);

const getDeviceHistory = createAction(`${prefix} Get Device History`, props<IHistoryRequest>());
const getDeviceHistorySuccess = createAction(
    `${prefix} Get Device History Success`,
    props<{ response: IDeviceHistory[] }>(),
);
const getDeviceHistoryError = createAction(
    `${prefix} Get Device History Error`,
    props<{ error: HttpErrorResponse }>(),
);

const showDeviceHistoryInfo = createAction(
    `${prefix} Show Device History Info Item`,
    props<{ historyInfo: IDeviceHistory }>(),
);
const hideDeviceHistoryInfo = createAction(`${prefix} Hide Device History Info Item`);
const hideHistoryPoint = createAction(`${prefix} Hide History Point`);

const showDeviceInfo = createAction(
    `${prefix} Show Device Info Item`,
    props<{ device: IDeviceFull | IDeviceByToken }>(),
);
const hideDeviceInfo = createAction(`${prefix} Hide Device Info Item`);
const hidePoint = createAction(`${prefix} Hide Point`);

const deleteDeviceHistory = createAction(`${prefix} Delete Device History`, props<IHistoryRequest>());
const deleteDeviceHistorySuccess = createAction(
    `${prefix} Delete Device History Success`,
    props<{ msg: string }>(),
);
const deleteDeviceHistoryError = createAction(
    `${prefix} Delete Device History Error`,
    props<{ error: HttpErrorResponse }>(),
);

const sendBeep = createAction(`${prefix} Send Beep`, props<{ peyload: any }>());
const sendBeepSuccess = createAction(`${prefix} Send Beep Success`, props<{ response: any }>());
const sendBeepError = createAction(`${prefix} Send Beep Error`, props<{ error: HttpErrorResponse }>());

const setDeviceSettings = createAction(`${prefix} Set Device Settings`, props<{ peyload: any }>());
const setDeviceSettingsSuccess = createAction(
    `${prefix} Set Device Settings Success`,
    props<{ deviceName: string; preferences: IDevicePreferences; msg: string; usageName?: string }>(),
);
const setDeviceSettingsError = createAction(
    `${prefix} Set Device Settings Error`,
    props<{ error: HttpErrorResponse }>(),
);

const shareDeviceLink = createAction(
    `${prefix} Share Device Link`,
    props<{ peyload: IShareDeviceLocationRequest }>(),
);
const shareDeviceLinkSuccess = createAction(
    `${prefix} Share Device Link Success`,
    props<IShareDeviceLocationResponse>(),
);
const shareDeviceLinkError = createAction(
    `${prefix} Share Device Link Error`,
    props<{ error: HttpErrorResponse }>(),
);

const deactivateShareLink = createAction(`${prefix} Deactivate Share Link`, props<{ devicesID: number[] }>());
const deactivateShareLinkSuccess = createAction(
    `${prefix} Deactivate Share Link Success`,
    props<{ msg: string }>(),
);
const deactivateShareLinkError = createAction(
    `${prefix} Deactivate Share Link Error`,
    props<{ error: HttpErrorResponse }>(),
);

const updateDeviceImage = createAction(`${prefix} Update Device Image`, props<{ peyload: File | number }>());
const updateDeviceImageSuccess = createAction(`${prefix} Update Device Image Success`, props<any>());
const updateDeviceImageError = createAction(
    `${prefix} Update Device Image Error`,
    props<{ error: HttpErrorResponse }>(),
);

const assignDevice = createAction(`${prefix} Assign Device`, props<{ zone: IZone }>());
const assignDeviceSuccess = createAction(
    `${prefix} Assign Device Success`,
    props<{ zone: IZone; msg: string }>(),
);
const assignDeviceError = createAction(
    `${prefix} Assign Device Error`,
    props<{ error: HttpErrorResponse }>(),
);

const unassignDevice = createAction(`${prefix} Unassign Device`, props<{ zone: IZone }>());
const unassignDeviceSuccess = createAction(
    `${prefix} Unassign Device Success`,
    props<{ zone: IZone; msg: string }>(),
);
const unassignDeviceError = createAction(
    `${prefix} Unassign Device Error`,
    props<{ error: HttpErrorResponse }>(),
);

const getRenewalPlans = createAction(`${prefix} Get Device Renewal Plans`, props<{ deviceID: number }>());
const getRenewalPlansSuccess = createAction(
    `${prefix} Get Device Renewal Plans Success`,
    props<{ plan: IPlan }>(),
);
const getRenewalPlansError = createAction(
    `${prefix} Get Device Renewal Plans Error`,
    props<{ error: HttpErrorResponse }>(),
);

const getBrainTreeDropInToken = createAction(
    `${prefix} Get BrainTree DropIn Token`,
    props<{ payload: IBrainTreeDropInTokenPayload }>(),
);
const getBrainTreeDropInTokenSuccess = createAction(
    `${prefix} Get BrainTree DropIn Token Success`,
    props<{ token: string }>(),
);
const getBrainTreeDropInTokenError = createAction(
    `${prefix} Get BrainTree DropIn Token Error`,
    props<{ error: HttpErrorResponse }>(),
);

const renewPlan = createAction(`${prefix} Renew Plan`, props<{ payload: IRenewPlanPayload }>());
const renewPlanSuccess = createAction(
    `${prefix} Renew Plan Success`,
    props<{ deviceID: number; msg: string }>(),
);
const renewPlanError = createAction(`${prefix} Renew Plan Error`, props<{ error: HttpErrorResponse }>());

const renewPrepaidPlan = createAction(
    `${prefix} Renew Prepaid Plan`,
    props<{ payload: IRenewPrepaidPlanPayload }>(),
);
const renewPrepaidPlanSuccess = createAction(
    `${prefix} Renew Prepaid Plan Success`,
    props<{ deviceID: number; msg: string }>(),
);
const renewPrepaidPlanError = createAction(
    `${prefix} Renew Prepaid Plan Error`,
    props<{ error: HttpErrorResponse }>(),
);

const renewPlanByCoupon = createAction(
    `${prefix} Renew Plan by Coupon`,
    props<{ payload: IRenewPlanByCouponPayload }>(),
);
const renewPlanByCouponSuccess = createAction(
    `${prefix} Renew Plan by Coupon Success`,
    props<{ deviceID: number; msg: string }>(),
);
const renewPlanByCouponError = createAction(
    `${prefix} Renew Plan by Coupon Error`,
    props<{ error: HttpErrorResponse }>(),
);

const increaseSMSLimit = createAction(
    `${prefix} Increase SMS Limit`,
    props<{ payload: IIncreaseSMSPlan; smsLimit: number; accountID?: number; deviceID?: number }>(),
);
const increaseSMSLimitSuccess = createAction(
    `${prefix} Increase SMS Limit Success`,
    props<{ smsLimit: number; haveSelectedDevice?: boolean }>(),
);
const increaseSMSLimitError = createAction(
    `${prefix} Increase SMS Limit Error`,
    props<{ error: HttpErrorResponse }>(),
);

const activatePrepaidPlan = createAction(
    `${prefix} Activate Prepaid Plan`,
    props<{ payload: IActivatePrepaidPlan }>(),
);
const activatePrepaidPlanSuccess = createAction(`${prefix} Activate Prepaid Plan Success`);
const activatePrepaidPlanError = createAction(
    `${prefix} Activate Prepaid Plan Error`,
    props<{ error: HttpErrorResponse }>(),
);

const activatePlanByCard = createAction(`${prefix} Activate Plan`, props<{ payload: IActivatePlan }>());
const activatePlanByCardSuccess = createAction(`${prefix} Activate Plan Success`);
const activatePlanByCardError = createAction(
    `${prefix} Activate Plan Error`,
    props<{ error: HttpErrorResponse }>(),
);

const activatePlanByCoupon = createAction(`${prefix} Activate Plan by Coupon`, props<{ coupon: string }>());
const activatePlanByCouponSuccess = createAction(`${prefix} Activate Coupon Success`);
const activatePlanByCouponError = createAction(
    `${prefix} Activate Coupon Error`,
    props<{ error: HttpErrorResponse }>(),
);

const removeBrainTreeDropInToken = createAction(`${prefix} Remove BrainTree DropIn Token`);

const getDeviceContactAssignments = createAction(`${prefix} Get Device Contact Assignments`);
const getDeviceContactAssignmentsSuccess = createAction(
    `${prefix} Get Device Contact Assignments Success`,
    props<{ response: any }>(),
);
const getDeviceContactAssignmentsError = createAction(
    `${prefix} Get Device Contact Assignments Error`,
    props<{ error: HttpErrorResponse }>(),
);

const setDeviceContactAssignments = createAction(
    `${prefix} Set Device Contact Assignments`,
    props<{ dataRequest: IContactAssignments }>(),
);
const setDeviceContactAssignmentsSuccess = createAction(
    `${prefix} Set Device Contact Assignments Success`,
    props<{ msg: string }>(),
);
const setDeviceContactAssignmentsError = createAction(
    `${prefix} Set Device Contact Assignments Error`,
    props<{ error: HttpErrorResponse }>(),
);

const getDevicesLocations = createAction(`${prefix} Get Device Locations`);
const getDevicesLocationsSuccess = createAction(
    `${prefix} Get Device Locations Success`,
    props<{ response: IDeviceLocationEvent[]; msg: string }>(),
);
const getDevicesLocationsError = createAction(
    `${prefix} Get Device Locations Error`,
    props<{ error: HttpErrorResponse }>(),
);

const pingDevicesLocations = createAction(`${prefix} Ping Device Locations`);
const pingDevicesLocationsSuccess = createAction(
    `${prefix} Ping Device Locations Success`,
    props<{ response: IDeviceLocationEvent[] }>(),
);
const pingDevicesLocationsError = createAction(
    `${prefix} Ping Device Locations Error`,
    props<{ error: HttpErrorResponse }>(),
);

const pingDeviceLocation = createAction(`${prefix} Ping Device Location`);
const pingDeviceLocationSuccess = createAction(
    `${prefix} Ping Device Location Success`,
    props<{ response: IDeviceLocationEvent }>(),
);
const pingDeviceLocationError = createAction(
    `${prefix} Ping Device Location Error`,
    props<{ error: HttpErrorResponse }>(),
);

const startPingDeviceLocation = createAction(`${prefix} Start Ping Device Location`);
const stopPingDeviceLocation = createAction(`${prefix} Stop Ping Device Location`);

const triggerDevicesLocations = createAction(`${prefix} Trigger Device Locations`);
const triggerDevicesLocationsSuccess = createAction(
    `${prefix} Trigger Device Locations Success`,
    props<{ msg: string }>(),
);
const triggerDevicesLocationsError = createAction(
    `${prefix} Trigger Device Locations Error`,
    props<{ error: HttpErrorResponse; msg: string }>(),
);

const triggerDeviceEvents = createAction(`${prefix} Trigger Device Events`);
const triggerDeviceEventsSuccess = createAction(
    `${prefix} Trigger Device Events Success`,
    props<{ response: IEventsResponse }>(),
);
const triggerDeviceEventsError = createAction(
    `${prefix} Trigger Device Locations Error`,
    props<{ error: HttpErrorResponse }>(),
);

const updateDeviceEvent = createAction(`${prefix} Update Device Event`, props<{ events: IEvent[] }>());
const updateDeviceEventSuccess = createAction(
    `${prefix} Update Device Event Success`,
    props<{ events: IEvent[] }>(),
);
const updateDeviceEventError = createAction(
    `${prefix} Update Device Event Error`,
    props<{ error: HttpErrorResponse }>(),
);

const getAddressForDevice = createAction(
    `${prefix} Get Address For Device`,
    props<{ deviceID: number; lat: number; lng: number }>(),
);
const getAddressForDeviceSuccess = createAction(
    `${prefix} Get Address For Device Success`,
    props<{ deviceID: number; address: string }>(),
);

const getAddressForDeviceError = createAction(
    `${prefix} Get Address For Device Error`,
    props<{ error: HttpErrorResponse; deviceID: number; errorText: string }>(),
);

const getDevicesSubscription = createAction(
    `${prefix} Get Devices Subscription`,
    props<IDevicesSubscriptionRequest>(),
);
const getDevicesSubscriptionSuccess = createAction(
    `${prefix} Get Devices Subscription Success`,
    props<{ response: ISubscription[] }>(),
);
const getDevicesSubscriptionError = createAction(
    `${prefix} Get Devices Subscription Error`,
    props<{ error: HttpErrorResponse }>(),
);

const getDevicePetDetails = createAction(
    `${prefix} Get Pet Details For Device`,
    props<{ deviceID: number }>(),
);
const getDevicePetDetailsSuccess = createAction(
    `${prefix} Get Pet Details Success`,
    props<{ response: any }>(),
);
const getDevicePetDetailsError = createAction(
    `${prefix} Get Pet Details Error`,
    props<{ error: HttpErrorResponse }>(),
);

const getDeviceSensorsDetails = createAction(
    `${prefix} Get DeviceSensorsDetails`,
    props<{ deviceID: number }>(),
);
const getDeviceSensorsDetailsSuccess = createAction(
    `${prefix} Get Device Sensors Details Success`,
    props<{ response: any }>(),
);
const getDeviceSensorsDetailsError = createAction(
    `${prefix} Get Device Sensors Details Error`,
    props<{ error: HttpErrorResponse }>(),
);

const setDevicePetDetails = createAction(
    `${prefix} Set Pet Details For Device`,
    props<{ deviceUsageDTO: IDeviceUsage }>(),
);
const setDevicePetDetailsSuccess = createAction(
    `${prefix} Set Pet Details Success`,
    props<{ response: any }>(),
);

const getDeviceFPIDetails = createAction(
    `${prefix} Get FPI Settings For Device`,
    props<{ accountID: number; deviceID: number }>(),
);
const getDeviceFPIDetailsSuccess = createAction(
    `${prefix} Get FPI Settings Success`,
    props<{ response: any }>(),
);
const getDeviceFPIDetailsError = createAction(
    `${prefix} Get FPI Settings Error`,
    props<{ error: HttpErrorResponse }>(),
);

const setDevicePetDetailsError = createAction(
    `${prefix} Set Pet Details Error`,
    props<{ error: HttpErrorResponse }>(),
);

const setFPIDetailsSettings = createAction(
    `${prefix} Set FPI settings for device`,
    props<{ accountID: number; deviceID: number; settings: IFPISettings }>(),
);
const setFPIDetailsSettingsSuccess = createAction(
    `${prefix} Set FPI settings success`,
    props<{ response: any }>(),
);
const setFPIDetailsSettingsError = createAction(
    `${prefix} Set FPI settings Error`,
    props<{ error: HttpErrorResponse }>(),
);

const updateFPIUsageSettings = createAction(
    `${prefix} Save FPI Usage settings for device`,
    props<{ accountID: number; deviceID: number; payload: { usage: string } }>(),
);
const updateFPIUsageSuccess = createAction(
    `${prefix} Save FPI Usage settings success`,
    props<{ response: any }>(),
);
const updateFPIUsageError = createAction(
    `${prefix} Save FPI Usage settings Error`,
    props<{ error: HttpErrorResponse }>(),
);

const resetPaymentProcessingStatus = createAction(`${prefix} Reset Payment Processing Action`);

const getPetActivityInfo = createAction(
    `${prefix} Get Pet Activity Information`,
    props<{ startDate: number; endDate: number }>(),
);
const getPetActivityInfoSuccess = createAction(
    `${prefix} Get Pet Activity Information Success`,
    props<{ response: IPetInfo }>(),
);
const getPetActivityInfoError = createAction(
    `${prefix} Get Pet Activity Information Error`,
    props<{ error: HttpErrorResponse }>(),
);

const getTotalDevicesCount = createAction(`${prefix} Get Main Account Related Devices Count`);
const getTotalDevicesCountSuccess = createAction(
    `${prefix} Get Main Account Related Devices Count Success`,
    props<{ response: IDevicesCount }>(),
);
const getTotalDevicesCountError = createAction(
    `${prefix} Get Main Account Related Devices Count Error`,
    props<{ error: HttpErrorResponse }>(),
);

const getDevicesFPIPendingFieldsCount = createAction(`${prefix} Get Devices FPI Pending Fields Count`);
const getDevicesFPIPendingFieldsCountSuccess = createAction(
    `${prefix} Get Devices FPI Pending Fields Count Success`,
    props<{ response: IPendingFields[] }>(),
);
const getDevicesFPIPendingFieldsCountError = createAction(
    `${prefix} Get Devices FPI Pending Fields Count Error`,
    props<{ error: HttpErrorResponse }>(),
);
// STRIPE device association with account
const associateDeviceWithAccountSuccess = createAction(
    `${prefix} Associate Device with Account Success`,
    props<{ deviceID: number; msg: string }>(),
);
const associateDeviceWithAccountError = createAction(
    `${prefix} Associate Device with Account Error`,
    props<{ error: HttpErrorResponse }>(),
);

const associateDeviceWithAccount = createAction(
    `${prefix} Associate Device with Account`,
    props<{ account_id: number; device_id: number; plan_id: number }>(),
);

const renewPlanWithStripe = createAction(
    `${prefix} Renew Plan with Stripe`,
    props<{ account_id: number; device_id: number; plan_id: number }>(),
);
const renewPlanWithStripeSuccess = createAction(
    `${prefix} Renew Plan with Stripe Success`,
    props<{ deviceID: number; msg: string }>(),
);
const renewPlanWithStripeError = createAction(
    `${prefix} Renew Plan with Stripe Error`,
    props<{ error: HttpErrorResponse }>(),
);

const changePingDeviceLocationInterval = createAction(
    `${prefix} Change Ping Device Location Interval`,
    props<{ pingInterval: number }>(),
);

const liveTracking = createAction(`${prefix} Device Live Tracking`, props<{ deviceIDs: number[] }>());
const liveTrackingSuccess = createAction(`${prefix} Device Live Tracking`);
const liveTrackingError = createAction(
    `${prefix} Device Live Tracking`,
    props<{ error: HttpErrorResponse }>(),
);

const stopLiveTracking = createAction(`${prefix} Device Stop Live Tracking`);

const clearRenewWithStripeStatus = createAction(`${prefix} Clear Renew Plan with Stripe Status`);

const getDevicesForFOTA = createAction(`${prefix} Get Devices For FOTA`);
const getDevicesForFOTASuccess = createAction(
    `${prefix} Get Devices For FOTA Success`,
    props<{ response: any }>(),
);
const getDevicesForFOTAError = createAction(
    `${prefix} Get Devices For FOTA Error`,
    props<{ error: HttpErrorResponse }>(),
);

const getFOTALogs = createAction(`${prefix} Get FOTA Logs`);
const getFOTALogsSuccess = createAction(`${prefix} Get FOTA Logs Success`, props<{ response: any }>());
const getFOTALogsError = createAction(`${prefix} Get FOTA Logs Error`, props<{ error: HttpErrorResponse }>());

export const DevicesActions = {
    getDevices,
    getDevicesSuccess,
    getDevicesError,

    clearDevices,

    getDeviceByID,
    getDeviceByIDSuccess,
    getDeviceByIDError,

    removeSelectedDevice,

    getDeviceDetailsByToken,
    getDeviceDetailsByTokenSuccess,
    getDeviceDetailsByTokenError,

    getDeviceHistory,
    getDeviceHistorySuccess,
    getDeviceHistoryError,

    showDeviceHistoryInfo,
    hideDeviceHistoryInfo,
    hideHistoryPoint,

    showDeviceInfo,
    hideDeviceInfo,
    hidePoint,

    deleteDeviceHistory,
    deleteDeviceHistorySuccess,
    deleteDeviceHistoryError,

    sendBeep,
    sendBeepSuccess,
    sendBeepError,

    setDeviceSettings,
    setDeviceSettingsSuccess,
    setDeviceSettingsError,

    shareDeviceLink,
    shareDeviceLinkSuccess,
    shareDeviceLinkError,

    deactivateShareLink,
    deactivateShareLinkSuccess,
    deactivateShareLinkError,

    updateDeviceImage,
    updateDeviceImageSuccess,
    updateDeviceImageError,

    assignDevice,
    assignDeviceSuccess,
    assignDeviceError,

    unassignDevice,
    unassignDeviceSuccess,
    unassignDeviceError,

    getRenewalPlans,
    getRenewalPlansSuccess,
    getRenewalPlansError,

    getBrainTreeDropInToken,
    getBrainTreeDropInTokenSuccess,
    getBrainTreeDropInTokenError,

    renewPlan,
    renewPlanSuccess,
    renewPlanError,

    renewPrepaidPlan,
    renewPrepaidPlanSuccess,
    renewPrepaidPlanError,

    renewPlanByCoupon,
    renewPlanByCouponSuccess,
    renewPlanByCouponError,

    removeBrainTreeDropInToken,

    getDeviceContactAssignments,
    getDeviceContactAssignmentsSuccess,
    getDeviceContactAssignmentsError,

    setDeviceContactAssignments,
    setDeviceContactAssignmentsSuccess,
    setDeviceContactAssignmentsError,

    getDevicesLocations,
    getDevicesLocationsSuccess,
    getDevicesLocationsError,

    pingDevicesLocations,
    pingDevicesLocationsSuccess,
    pingDevicesLocationsError,

    pingDeviceLocation,
    pingDeviceLocationSuccess,
    pingDeviceLocationError,

    startPingDeviceLocation,
    stopPingDeviceLocation,

    triggerDevicesLocations,
    triggerDevicesLocationsSuccess,
    triggerDevicesLocationsError,

    triggerDeviceEvents,
    triggerDeviceEventsSuccess,
    triggerDeviceEventsError,

    updateDeviceEvent,
    updateDeviceEventSuccess,
    updateDeviceEventError,

    getAddressForDevice,
    getAddressForDeviceSuccess,
    getAddressForDeviceError,

    increaseSMSLimit,
    increaseSMSLimitSuccess,
    increaseSMSLimitError,

    activatePrepaidPlan,
    activatePrepaidPlanSuccess,
    activatePrepaidPlanError,

    activatePlanByCard,
    activatePlanByCardSuccess,
    activatePlanByCardError,

    activatePlanByCoupon,
    activatePlanByCouponSuccess,
    activatePlanByCouponError,

    getDevicesSubscription,
    getDevicesSubscriptionSuccess,
    getDevicesSubscriptionError,

    resetPaymentProcessingStatus,

    getDevicePetDetails,
    getDevicePetDetailsSuccess,
    getDevicePetDetailsError,

    setDevicePetDetails,
    setDevicePetDetailsSuccess,
    setDevicePetDetailsError,

    getDeviceFPIDetails,
    getDeviceFPIDetailsSuccess,
    getDeviceFPIDetailsError,

    setFPIDetailsSettings,
    setFPIDetailsSettingsSuccess,
    setFPIDetailsSettingsError,

    updateFPIUsageSettings,
    updateFPIUsageSuccess,
    updateFPIUsageError,

    getDeviceSensorsDetails,
    getDeviceSensorsDetailsSuccess,
    getDeviceSensorsDetailsError,

    getPetActivityInfo,
    getPetActivityInfoSuccess,
    getPetActivityInfoError,

    getTotalDevicesCount,
    getTotalDevicesCountSuccess,
    getTotalDevicesCountError,

    getDevicesFPIPendingFieldsCount,
    getDevicesFPIPendingFieldsCountSuccess,
    getDevicesFPIPendingFieldsCountError,

    associateDeviceWithAccount,
    associateDeviceWithAccountSuccess,
    associateDeviceWithAccountError,

    renewPlanWithStripe,
    renewPlanWithStripeSuccess,
    renewPlanWithStripeError,
    clearRenewWithStripeStatus,

    changePingDeviceLocationInterval,

    liveTracking,
    liveTrackingSuccess,
    liveTrackingError,

    stopLiveTracking,

    getDevicesForFOTA,
    getDevicesForFOTASuccess,
    getDevicesForFOTAError,

    getFOTALogs,
    getFOTALogsSuccess,
    getFOTALogsError,
};

import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { skin } from 'src/white-labels';
import { LocalStorageService } from './store/services/local-storage.service';
import { LocaleService, TutorialsService, WindowService } from './store/services';
import { ILanguage } from './store/interfaces';
import { LANGUAGES, LANGUAGES_MAMORI } from './store/constants';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
    favIcon: HTMLLinkElement = this.document.querySelector('#favIcon');
    languagesList: ILanguage[];
    skin = skin;

    constructor(
        translate: TranslateService,
        public tutorials: TutorialsService,
        private localStorage: LocalStorageService,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private titleService: Title,
        private localeService: LocaleService,
    ) {
        this.titleService.setTitle(skin.brandName);

        if (skin.whiteLabel === 'TAGANDTRACK') {
            this.favIcon.href = 'assets/tnt-favicon.ico';
        } else if (skin.whiteLabel === 'TRACKIPET') {
            this.favIcon.href = 'assets/trackipet-favicon.ico';
        }

        this.renderer.addClass(this.document.body, skin.whiteLabel.toLocaleLowerCase());

        switch (this.skin.whiteLabel) {
            case 'MAMORI':
                this.languagesList = LANGUAGES_MAMORI;
                break;
            default:
                this.languagesList = LANGUAGES;
                break;
        }

        const navigatorLang = this.localeService.getUsersLocale().split('-')[0];
        const lang = this.languagesList.find((inLang) => inLang.value === navigatorLang);

        if (lang) {
            translate.setDefaultLang(lang.value);
            if (!this.localStorage.getItem('language')) {
                this.localStorage.setItem('language', lang.value); // Set default language to English(en)
            } else {
                translate.use(this.localStorage.getItem('language'));
            }
        } else {
            translate.setDefaultLang('en');
        }
    }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AuthActions } from 'src/app/store/actions/auth.actions';
import { EMAIL_VALIDATOR_REG_EXP } from 'src/app/store/constants/common.constants';
import { IAppState } from 'src/app/store/state/app.state';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent {
    forgotPasswordForm: FormGroup = this.formBuilder.group({
        email: ['', [Validators.required, Validators.pattern(EMAIL_VALIDATOR_REG_EXP)]],
    });

    constructor(private formBuilder: FormBuilder, private store: Store<IAppState>) {}

    onSubmit(isValid: boolean) {
        this.forgotPasswordForm.markAllAsTouched();
        if (isValid) {
            this.store.dispatch(
                AuthActions.forgotPassword({
                    email: this.forgotPasswordForm.value.email,
                    whitelabel: skin.whiteLabel,
                }),
            );
        }
    }
}

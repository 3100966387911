import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthActions } from 'src/app/store/actions/auth.actions';
import { StatusLabelActions } from 'src/app/store/actions/status-label.actions';
import { ILogin, ISocialUserInfo, IStatusLabel } from 'src/app/store/interfaces';
import { selectIsLoginProcessing, selectIsStatusLabel } from 'src/app/store/selectors/status.selectors';
import { AuthService } from 'src/app/store/services/auth/auth.service';
import { IAppState } from 'src/app/store/state/app.state';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-welcome-back',
    templateUrl: './welcome-back.component.html',
    styleUrls: ['./welcome-back.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeBackComponent implements OnInit {
    form: FormGroup = this.formBuilder.group({
        password: ['', [Validators.required]],
        isKeepLogged: [true],
    });
    loginError$: Observable<IStatusLabel>;
    loginProcessing$: Observable<boolean>;
    lastLoginUserInfo: ISocialUserInfo;
    skin = skin;
    showPassword = false;

    constructor(
        private router: Router,
        private store: Store<IAppState>,
        private authService: AuthService,
        private formBuilder: FormBuilder,
    ) {}

    ngOnInit(): void {
        this.loginError$ = this.store.select(selectIsStatusLabel);
        this.loginProcessing$ = this.store.select(selectIsLoginProcessing);

        if (this.authService.lastLoginUserInfo) {
            this.lastLoginUserInfo = {
                ...this.authService.lastLoginUserInfo,
                provider: this.authService.lastLoginUserInfo.provider.toLocaleLowerCase(),
            };
        } else {
            this.router.navigate(['/']);
        }
    }

    onSubmit(isValid: boolean) {
        if (isValid) {
            const userData: ILogin = {
                username: this.lastLoginUserInfo.email,
                password: this.form.value.password.trim(),
                remember_me: this.form.value.isKeepLogged,
                whitelabel: skin.whiteLabel,
            };
            this.store.dispatch(StatusLabelActions.hideStatusLabel());
            this.store.dispatch(AuthActions.login({ userData }));
        }
    }

    changeVisiblePassword(): void {
        this.showPassword = !this.showPassword;
    }
}
